<template>
  <StoreFrontNavigation :is-barebone="isUnderConstruction"></StoreFrontNavigation>

  <div class="content">
    <div class="content-inner">
      <router-view></router-view>
    </div>
  </div>

  <StoreFrontFooter v-if="!isUnderConstruction"></StoreFrontFooter>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import StoreFrontFooter from "@/components/storefront/StoreFrontFooter.vue";
import StoreFrontNavigation from "@/components/storefront/StoreFrontNavigation.vue";
import { useUserStatusStore } from "@/stores/userStatusStore";

export default defineComponent({
  name: "StripedContentWrapper",
  components: {
    StoreFrontFooter,
    StoreFrontNavigation,
  },
  setup() {
    const userStatusStore = useUserStatusStore();

    const isUnderConstruction = computed(() => userStatusStore.isUnderConstruction);

    return {
      isUnderConstruction,
    };
  },
});
</script>

<style scoped lang="scss">
.content {
  --var-stripes-width: 5.5% /* 2x 9 stripes (9 white + 9 ff-color) */;
  --var-stripes-width-x2: calc(2 * var(--var-stripes-width));

  background-image: repeating-linear-gradient(135deg, transparent, transparent var(--var-stripes-width), #fff 0, #fff var(--var-stripes-width-x2));
  background-color: var(--ff-random-package-color);

  :deep(.content-inner) {
    box-shadow: 0 0 10px 5px rgba($ff-primary-black, 10%);
    max-width: 500px;
    padding: 40px;
    margin: 100px auto;
    background-color: $ff-primary-white;

    h2 {
      margin-bottom: 20px;
    }

    .primary-button {
      width: 100%;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .primary-button,
      span:not(:first-child),
      a {
        margin-top: 30px;
      }

      .p-inputtext {
        &:hover,
        &:focus {
          border-color: var(--ff-random-package-color);
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem var(--ff-random-label-color);
        }
      }

      .p-password,
      input {
        width: 100%;

        &.p-inputtext {
          padding-left: 40px;
        }
      }

      i.pi-lock {
        z-index: 1;
      }
    }
  }
}
</style>
