<template>
  <h2><Translate text="STOREFRONT_FORMS_LOGIN" /></h2>

  <template v-if="!isAuthenticated">
    <form @submit.prevent="" @change="validator.validateForm(form as LoginFormData, dto)">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-user"></i>
        <InputText
          type="text"
          id="username"
          name="username"
          autocomplete="username"
          v-on:keyup.enter="login()"
          :disabled="isLoading"
          v-model="form.username"
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'username') }"
        />
        <label for="username"><Translate text="STOREFRONT_FORMS_LOGIN_USERNAME" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'username')"><Translate text="STOREFRONT_FORMS_LOGIN_USERNAME_VALIDATION" /></small>

      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-lock"></i>
        <Password
          type="password"
          id="password"
          name="password"
          :inputProps="{ autocomplete: 'current-password' }"
          v-on:keyup.enter="login()"
          :disabled="isLoading"
          v-model="form.password"
          :feedback="false"
          toggleMask
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'password') }"
        />
        <label for="password"><Translate text="STOREFRONT_FORMS_LOGIN_PASSWORD" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'password')"><Translate text="STOREFRONT_FORMS_LOGIN_PASSWORD_VALIDATION" /></small>

      <FFButton :label="Translator.translate('STOREFRONT_FORMS_LOGIN')" @click="login()" :loading="isLoading" color="var(--ff-random-label-color)"></FFButton>

      <Message v-if="hasLoginErrorMessage" severity="error"><Translate text="STOREFRONT_MESSAGES_LOGIN_INVALID" /></Message>

      <router-link v-if="!isUnderConstruction" to="/forgot-password" class="forgot-password"><Translate text="STOREFRONT_FORMS_LOGIN_FORGOT_PASSWORD" /></router-link>

      <FFButton v-if="!isUnderConstruction" label="Jetzt registrieren" @click="goToRegister()" :loading="isLoading" color="var(--ff-fisherman-s-friend-black-70)" class="register"></FFButton>
    </form>
  </template>

  <template v-else>
    <p><Translate text="STOREFRONT_MESSAGES_LOGIN_ALREADY_LOGGEDIN" /></p>
    <FFButton :label="Translator.translate('STOREFRONT_FORMS_LOGIN_LOGOUT')" @click="logout()" :loading="isLoading" color="var(--ff-random-label-color)"></FFButton>
  </template>
</template>

<script lang="ts">
import { UserDto } from "@/dtos/UserDtos";
import { LoginData } from "@/dtos/data/LoginData";
import { LoginFormData } from "@/dtos/data/LoginFormData";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Validator } from "@/helpers/Validator";
import { UserModel } from "@/models/UserModel";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { computed, defineComponent, ref } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import Translate from "@/components/shared/Translate.vue";
import { Translator } from "@/helpers/Translator";
import { LoginReturnCode } from "@/enums/LoginReturnCode";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

/**
 * The shared login panel component.
 */
export default defineComponent({
  name: "Login",
  components: { FFButton, Translate },
  methods: {
    async login(): Promise<void> {
      const store = useUserStatusStore();

      this.form.shouldValidate = true;

      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      this.validator.validateForm(this.form as LoginFormData, this.dto).then((success) => {
        store.postLogin(this.form).then((loginReturnCode: LoginReturnCode) => {
          if (loginReturnCode === LoginReturnCode.NEW_PASSWORD_REQUIRED) {
            this.$router.push("/new-password");
          } else if (loginReturnCode === LoginReturnCode.VERIFICATION_PENDING) {
            this.$router.push("/not-verified");
          } else {
            this.$router.push("/");
          }
        });
      });
    },

    async logout(): Promise<void> {
      const store = useUserStatusStore();

      store.postLogout().then(() => {
        this.$router.push("/");
      });
    },

    goToRegister(): void {
      this.$router.push("/register");
    },
  },
  setup() {
    const userStatusStore = useUserStatusStore();

    const validator = new Validator<LoginData>();
    const formHelper = new FormHelper("/login");

    const isAuthenticated = computed(() => userStatusStore.isAuthenticated);
    const isUnderConstruction = computed(() => userStatusStore.isUnderConstruction);
    const isLoading = computed(() => userStatusStore.userDto.isLoading);
    const hasLoginErrorMessage = computed(() => userStatusStore.userDto.hasError);

    const dto = ref(new UserDto(new UserModel()));
    const form = ref(new LoginFormData(dto.value));

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_FORMS_LOGIN")),
    });

    return {
      isAuthenticated,
      isUnderConstruction,
      isLoading,
      hasLoginErrorMessage,
      validator,
      formHelper,
      form,
      dto,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss"></style>
