import { Constants } from "@/enums/Constants";
import { AxiosResponse } from "axios";
import { ApiControllerCore } from "./ApiControllerCore";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";
import { DateHelper } from "@/helpers/DateHelper";

export class ReportingApiController extends ApiControllerCore {
  protected apiEndpoint: string;

  constructor() {
    super();

    this.apiEndpoint = Constants.ApiEndpoints.REPORTING;
  }

  public async getProductsReport(from: Date, to: Date): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/products?fromDate=${DateHelper.toISOStringWithTimezone(from)}&toDate=${DateHelper.toISOStringWithTimezone(to)}`);
  }

  public async getOrdersReport(from: Date, to: Date): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/orders?fromDate=${DateHelper.toISOStringWithTimezone(from)}&toDate=${DateHelper.toISOStringWithTimezone(to)}`);
  }

  public async getUsersReport(from: Date, to: Date): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/users?fromDate=${DateHelper.toISOStringWithTimezone(from)}&toDate=${DateHelper.toISOStringWithTimezone(to)}`);
  }

  public async getSecurityReport(from: Date, to: Date): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/security?fromDate=${DateHelper.toISOStringWithTimezone(from)}&toDate=${DateHelper.toISOStringWithTimezone(to)}`);
  }
}
