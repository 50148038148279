<template>
  <fieldset v-if="form.isReady()" :disabled="isArchived(dto.model)">
    <Message v-if="isArchived(dto.model)" severity="warn" class="archived" :closable="false">Dieses Produkt ist archiviert.</Message>

    <form @submit.prevent="" @change="validateForm()">
      <Panel header="Produktinformationen">
        <!-- Active -->
        <div class="active-panel">
          <InputSwitch id="active" v-model="form.isActive" :disabled="isLoading || isArchived(dto.model)" />
          <label for="active" class="p-label">{{ form.isActive ? "Aktiv" : "Inaktiv" }}</label>
        </div>

        <!-- Article Number -->
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-hashtag"></i>
            <InputText
              type="text"
              id="articleNumber"
              :disabled="isLoading"
              v-model="form.articleNumber"
              class="required"
              :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'articleNumber') }"
            />
            <label for="articleNumber">Artikelnummer</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'articleNumber')">Artikelnummer ist ein Pflichtfeld.</small>
        </div>

        <!-- Name -->
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-pencil"></i>
            <InputText type="text" id="nameDe" :disabled="isLoading" v-model="form.nameDe" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'nameDe') }" />
            <label for="nameDe">Name Deutsch</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'nameDe')">Name Deutsch ist ein Pflichtfeld.</small>

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-pencil"></i>
            <InputText type="text" id="nameFr" :disabled="isLoading" v-model="form.nameFr" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'nameFr') }" />
            <label for="nameFr">Name Französisch</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'nameFr')">Name Französisch ist ein Pflichtfeld.</small>
        </div>

        <!-- Subname -->
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-pencil"></i>
            <InputText type="text" id="subnameDe" :disabled="isLoading" v-model="form.subnameDe" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'subnameDe') }" />
            <label for="subnameDe">Subname Deutsch</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'subnameDe')">Subname Deutsch ist ein Pflichtfeld.</small>

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-pencil"></i>
            <InputText type="text" id="subnameFr" :disabled="isLoading" v-model="form.subnameFr" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'subnameFr') }" />
            <label for="subnameFr">Subname Französisch</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'subnameFr')">Subname Französisch ist ein Pflichtfeld.</small>
        </div>

        <!-- Description -->
        <div class="form-row split">
          <div>
            <span class="p-float-label p-input-icon-left p-float">
              <i class="pi pi-list"></i>
              <label for="descriptionDe">Beschreibung Deutsch</label>
            </span>
            <Editor
              ref="editorDeRef"
              :readonly="isLoading || isArchived(dto.model)"
              v-model="form.descriptionDe"
              editorStyle="height: 250px;font-size: 1em;"              
              :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'descriptionDe') }"
            >
              <template v-slot:toolbar>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                  <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                  <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                </span>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Image'" class="ql-image"></button>
                  <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                </span>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Ordered List'" class="ql-list" value="ordered"></button>
                  <button v-tooltip.bottom="'Bullet List'" class="ql-list" value="bullet"></button>
                  <button v-tooltip.bottom="'Header'" class="ql-header" value="1"></button>
                </span>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Formatierung entfernen'" class="ql-clean"></button>
                </span>
              </template>
            </Editor>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'descriptionDe')">Beschreibung Deutsch ist ein Pflichtfeld.</small>
          </div>

          <div>
            <span class="p-float-label p-input-icon-left p-float">
              <i class="pi pi-list"></i>
              <label for="descriptionFr">Beschreibung Französisch</label>
            </span>
            <Editor
              ref="editorFrRef"
              :readonly="isLoading || isArchived(dto.model)"
              v-model="form.descriptionFr"
              editorStyle="height: 250px;font-size: 1em;"
              :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'descriptionFr') }"
            >
              <template v-slot:toolbar>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                  <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                  <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                </span>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Image'" class="ql-image"></button>
                  <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                </span>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Ordered List'" class="ql-list" value="ordered"></button>
                  <button v-tooltip.bottom="'Bullet List'" class="ql-list" value="bullet"></button>
                  <button v-tooltip.bottom="'Header'" class="ql-header" value="1"></button>
                </span>
                <span class="ql-formats">
                  <button v-tooltip.bottom="'Formatierung entfernen'" class="ql-clean"></button>
                </span>
              </template>
            </Editor>
            <small class="p-error" v-show="validator.hasValidationErrors(dto, 'descriptionFr')">Beschreibung Französisch ist ein Pflichtfeld.</small>
          </div>
        </div>

        <!-- Color Themes -->
        <div class="form-row short">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-pencil"></i>
            <Dropdown
              v-model="form.colorTheme"
              :options="availableColorThemes"
              :disabled="isLoading || isArchived(dto.model)"
              option-value="name"
              placeholder="Wähle ein Farbschema"
              @update:modelValue="validateForm()"
              :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'colorTheme') }"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div class="color-preview stripes" :style="{ backgroundColor: getColorTheme(slotProps.value)?.packageColor }"></div>
                  <div class="color-preview" :style="{ backgroundColor: getColorTheme(slotProps.value)?.labelColor }"></div>
                  <div>{{ capitalizeFirstLetter(slotProps.value) }}</div>
                </div>
                <div v-else>&nbsp;</div>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <div class="color-preview stripes" :style="{ backgroundColor: slotProps.option.packageColor }"></div>
                  <div class="color-preview" :style="{ backgroundColor: slotProps.option.labelColor }"></div>
                  <div>{{ capitalizeFirstLetter(slotProps.option.name) }}</div>
                </div>
              </template>
            </Dropdown>
            <label for="colorTheme">Farbschema</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'colorTheme')">Farbschema ist ein Pflichtfeld.</small>
        </div>

        <!-- Price -->
        <div class="form-row short">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-tag"></i>
            <InputNumber
              id="price"
              :disabled="isLoading"
              v-model="form.price"
              :min="1"
              showButtons
              :useGrouping="false"
              class="required"
              :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'price') }"
              @update:modelValue="validateForm()"
            />
            <label for="price">Preis</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'price')">Preis ist ein Pflichtfeld.</small>
        </div>

        <!-- Stock -->
        <div class="form-row short">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-inbox"></i>
            <InputNumber
              id="stock"
              :disabled="isLoading"
              v-model="form.stock"
              showButtons
              :useGrouping="false"
              class="required"
              :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'stock') }"
              @update:modelValue="validateForm()"
            />
            <label for="stock">Lagerbestand</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'stock')">Lagerbestand ist ein Pflichtfeld.</small>
        </div>
      </Panel>

      <Panel header="Verlinkungen">
        <!-- Product Categories -->
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-inbox"></i>
            <MultiSelect
              v-model="form.productCategories"
              :options="availableProductCategories"
              display="chip"
              optionLabel="nameDe"
              placeholder="&nbsp;"
              valueLabel="nameDe"
              data-key="id"
              :disabled="isLoading || isArchived(dto.model)"
              @update:modelValue="validateForm()"
            ></MultiSelect>
            <label for="productCategories">Produktkategorien</label>
          </span>
        </div>

        <!-- Similar Products -->
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-inbox"></i>
            <MultiSelect
              v-model="form.similarProducts"
              :options="availableActiveProducts"
              display="chip"
              optionLabel="nameDe"
              placeholder="&nbsp;"
              valueLabel="nameDe"
              data-key="id"
              filter
              :maxSelectedLabels="8"
              :selectionLimit="3"
              :disabled="isLoading || isArchived(dto.model)"
              variant="filled"
              @update:modelValue="validateForm()"
            >
              <template #option="slotProps">
                <div class="flex items-center">
                  <div>{{ slotProps.option.nameDe }}</div>
                </div>
              </template>
            </MultiSelect>
            <label for="productCategories">Ähnliche Produkte</label>
          </span>
        </div>
      </Panel>

      <div class="form-row">
        <!-- Images -->
        <span class="p-float-label p-input-icon-left">
          <label for="productImages" class="p-float">Bilder</label>

          <FileUpload
            ref="imageUploadRef"
            name="form.productImages"
            url="/api/upload"
            :multiple="true"
            accept="image/*"
            :maxFileSize="2 /*MB*/ * 1024 * 1024"
            @select="onUploadImage"
            :disabled="isLoading || isArchived(dto.model)"
            chooseLabel="Bilder hinzufügen..."
            :showUploadButton="false"
            :showCancelButton="false"
          >
            <template #content>
              <template v-if="sortedImages.length > 0">
                <div v-for="(image, index) of sortedImages" :key="image.id" class="fileupload-entry shadow-2" :class="{ highlighted: isHighlightImage(image), disabled: isLoading }">
                  <div class="fileupload-entry-actions">
                    <Button icon="pi pi-trash" @click="onRemoveImage(index)" :severity="'danger'" :loading="isLoading"></Button>
                  </div>
                  <img :src="image.imageData" />
                  <div class="fileupload-entry-actions">
                    <i class="pi pi-arrow-left" @click="onSortOrderReduced(index)" :class="{ disabled: index === 0 }"></i>
                    <div>
                      <span>{{ image.sortOrder }}</span>
                      <small>Position</small>
                    </div>
                    <i class="pi pi-arrow-right" @click="onSortOrderIncreased(index)" :class="{ disabled: index === sortedImages.length - 1 }"></i>
                  </div>
                </div>
              </template>
              <template v-else>
                <p>Bilder direkt hier rein ziehen...</p>
              </template>
            </template>
          </FileUpload>
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'productImages')">Bilder ist ein Pflichtfeld.</small>
      </div>

      <Message v-if="dto.hasError" severity="error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</Message>

      <!-- Button Panel -->
      <div class="button-panel">
        <Button type="button" label="Löschen" :severity="'danger'" icon="pi pi-trash" @click="onDelete($event)" :loading="isLoading"></Button>

        <div>
          <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
          <Button type="submit" label="Speichern" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="isLoading"></Button>
        </div>
      </div>

      <MetadataPanel :dto="dto"></MetadataPanel>
    </form>
  </fieldset>

  <div v-else-if="isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>

  <ConfirmDialog></ConfirmDialog>
  <Toast class="control-panel-toast">
    <template #message="slotProps">
      <div class="">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <Button v-if="slotProps.message.severity === 'success'" class="confirm-button" :size="'small'" label="Zurück zur Liste" :severity="'success'" @click="formHelper.returnToList()"></Button>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { ProductFormData } from "@/dtos/data/ProductFormData";
import { useProductStore } from "@/stores/productStore";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { IProductModel } from "@/models/interfaces/IProductModel";
import { ToastMessageOptions } from "primevue/toast";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import FileUpload, { FileUploadSelectEvent } from "primevue/fileupload";
import { ProductImageModel } from "@/models/ProductImageModel";
import { Constants } from "@/enums/Constants";
import stringMixins from "@/mixins/stringMixins";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { useProductCategoryStore } from "@/stores/productCategoryStore";
import { IColorTheme } from "@/dtos/shared/IColorTheme";
import { ProductDto, ProductCategoryListDto, ProductListDto } from "@/dtos/ProductDtos";
import Editor from "primevue/editor";
import MetadataPanel from "../MetadataPanel.vue";
import modelMixins from "@/mixins/modelMixins";

/**
 * A shared component used for editing and creating products.
 */
export default defineComponent({
  name: "ProductsCreateEdit",
  props: {
    id: Number,
    dto: {
      type: ProductDto,
      required: true,
    },
  },
  components: { LoadingSkeleton, MetadataPanel },
  methods: {
    async onSave() {
      const store = useProductStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // validate before save.
      this.validateForm().then(() => {
        // re-assign sort orders.
        this.dto.model.productImages.forEach((image, index) => {
          image.sortOrder = index + 1;
        });

        this.isLoadingRequest = true;

        if (this.form.isNew()) {
          // remember objects for later.
          const imagesToCreate = this.dto.model.productImages;
          const categoriesToCreate = this.dto.model.productCategories;
          const similarProductsToCreate = this.dto.model.similarProducts;

          // send POST requests...
          store
            .createProduct(this.dto as ProductDto)
            .then(async () => {
              imagesToCreate.forEach((imageToCreate) => (imageToCreate.productId = this.dto.model.id));
              const imageRequests = imagesToCreate.map((imageToCreate) => store.createProductImage(this.dto, imageToCreate));
              return Promise.all(imageRequests);
            })
            .then(async () => {
              const categoryRequests = categoriesToCreate.map((categoryToCreate) => store.createProductCategory(this.dto, categoryToCreate));
              return Promise.all(categoryRequests);
            })
            .then(async () => {
              const similarProductsRequests = similarProductsToCreate.map((similarProductToCreate) => store.createSimilarProduct(this.dto, similarProductToCreate));
              return Promise.all(similarProductsRequests);
            })
            .then(async () => {
              // return to list.
              this.formHelper.returnToList();
            })
            .catch(async () => {
              // show toast if successful.
              this.showToast({
                life: 3000,
                severity: "error",
                summary: "Fehlgeschlagen",
                detail: "Das Produkt konnte nicht erfasst werden.",
              });
            });
        } else {
          // remember objects for later.
          const imagesToCreate = this.dto.model.productImages.filter((i) => i.id === -1);
          const imagesToUpdate = this.dto.model.productImages.filter((i) => this.form.originalData.productImages.some((orig) => orig.id === i.id));
          const imagesToDelete = this.form.originalData.productImages.filter((orig) => this.dto.model.productImages.every((i) => orig.id !== i.id));
          const categoriesToCreate = this.dto.model.productCategories.filter((orig) => this.form.originalData.productCategories.every((i) => orig.id !== i.id));
          const categoriesToDelete = this.form.originalData.productCategories.filter((orig) => this.dto.model.productCategories.every((i) => orig.id !== i.id));
          const similarProductsToCreate = this.dto.model.similarProducts.filter((orig) => this.form.originalData.similarProducts.every((i) => orig.id !== i.id));
          const similarProductsToDelete = this.form.originalData.similarProducts.filter((orig) => this.dto.model.similarProducts.every((i) => orig.id !== i.id));

          // send PUT requests...
          store
            .updateProduct(this.dto as ProductDto)
            .then(async () => {
              const imageRequests: Promise<void>[] = [];
              imageRequests.push(
                ...imagesToCreate.map((imageToCreate) => {
                  return store.createProductImage(this.dto, imageToCreate);
                }),
                ...imagesToUpdate.map((imageToUpdate) => {
                  return store.updateProductImage(this.dto, imageToUpdate);
                }),
                ...imagesToDelete.map((imageToDelete) => {
                  return store.deleteProductImage(this.dto, imageToDelete);
                })
              );
              return Promise.all(imageRequests);
            })
            .then(async () => {
              const categoryRequests: Promise<void>[] = [];
              categoryRequests.push(
                ...categoriesToCreate.map((categoryToCreate) => {
                  return store.createProductCategory(this.dto, categoryToCreate);
                }),
                ...categoriesToDelete.map((categoryToDelete) => {
                  return store.deleteProductCategory(this.dto, categoryToDelete);
                })
              );
              return Promise.all(categoryRequests);
            })
            .then(async () => {
              const similarProductsRequests: Promise<void>[] = [];
              similarProductsRequests.push(
                ...similarProductsToCreate.map((similarProductToCreate) => {
                  return store.createSimilarProduct(this.dto, similarProductToCreate);
                }),
                ...similarProductsToDelete.map((similarProductToDelete) => {
                  return store.deleteSimilarProduct(this.dto, similarProductToDelete);
                })
              );
              return Promise.all(similarProductsToCreate);
            })
            .then(() => {
              // wait to give server enough time to process previous requests.
              return new Promise((resolve) => setTimeout(resolve, 100));
            })
            .then(async () => {
              // special case: reload product, because relations have been updated.
              return store.getProduct(this.dto, Number(this.id)).then(async () => {
                // special case: reload form data, because relations have been updated.
                this.form = new ProductFormData(this.dto);
              });
            })
            .then(async () => {
              // show toast if successful.
              this.showToast({
                life: 5000,
                severity: "success",
                summary: "Erfolgreich gespeichert",
                detail: "Das Produkt wurde erfolgreich aktualisiert.",
              });
            })
            .catch(async () => {
              // show toast if successful.
              this.showToast({
                life: 3000,
                severity: "error",
                summary: "Fehlgeschlagen",
                detail: "Das Produkt konnte nicht aktualisiert werden.",
              });
            })
            .finally(() => {
              this.isLoadingRequest = false;
            });
        }
      });
    },

    async onDelete(event: Event): Promise<void> {
      const store = useProductStore();

      this.formHelper.promptForDeletion(
        event,
        () => {
          // send DELETE request and redirect if success.
          store.deleteProduct(this.dto as ProductDto).then(async () => {
            // return to list.
            this.formHelper.returnToList();
          });
        },
        () => {}
      );
    },

    async onUploadImage(event: FileUploadSelectEvent): Promise<void> {
      const files = event.files as File[];
      const fileParses: Promise<void>[] = [];

      files.forEach((file) => {
        const operation = this.formHelper.blobImageToBase64(file).then((base64) => {
          // reference is used to track newly added images, so not to add the same twice.
          const reference = file.name + file.size;

          if (this.dto.model.productImages.every((i) => i._reference !== reference)) {
            this.dto.model.productImages.push({
              id: -1,
              productId: this.dto.model.id,
              imageData: base64,
              sortOrder: this.dto.model.productImages.length + 1,
              _reference: reference,
            } as ProductImageModel);
          }
        });
        fileParses.push(operation);
      });

      Promise.all(fileParses).then(() => this.validateForm());
    },

    async onRemoveImage(index: number): Promise<void> {
      this.dto.model.productImages.splice(index, 1);

      this.validateForm();
    },

    // reducing means closer to the first picture.
    async onSortOrderReduced(index: number): Promise<void> {
      if (index <= 0) {
        return;
      }

      const otherIndex = index - 1;
      const tempOrder = this.form.productImages[index].sortOrder;

      this.form.productImages[index].sortOrder = this.form.productImages[otherIndex].sortOrder;
      this.form.productImages[otherIndex].sortOrder = tempOrder;
    },

    // increasing means closer to the last picture.
    async onSortOrderIncreased(index: number): Promise<void> {
      if (index >= this.form.productImages.length - 1) {
        return;
      }

      const otherIndex = index + 1;
      const tempOrder = this.form.productImages[index].sortOrder;

      this.form.productImages[index].sortOrder = this.form.productImages[otherIndex].sortOrder;
      this.form.productImages[otherIndex].sortOrder = tempOrder;
    },

    getColorTheme(name: string): IColorTheme | undefined {
      return this.availableColorThemes.find((c) => c.name === name);
    },

    async validateForm(): Promise<void> {
      return this.validator.validateForm(this.form as ProductFormData, this.dto);
    },
  },
  setup(props) {
    const toast = useToast();
    const productCategoryStore = useProductCategoryStore();
    const productStore = useProductStore();

    const validator = new Validator<IProductModel>();
    const formHelper = new FormHelper("/controlpanel/products");

    const availableColorThemes = Constants.ColorThemes;

    const availableProductCategoriesDto = ref(new ProductCategoryListDto([]));
    const availableActiveProductsDto = ref(new ProductListDto([]));

    const imageUploadRef = ref(null as FileUpload | null);

    const isLoadingRequest = ref(false);
    const isLoading = computed(() => isLoadingRequest.value || dto.value.isLoading);

    // get entity from props.
    const { id, dto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new ProductFormData(dto.value));

    // setup form watchers.
    const updateFormData = (newValue: ProductDto) => {
      if (newValue.model.id !== undefined && !form.value.isExistingOrNew()) {
        form.value = new ProductFormData(newValue);
      }
    };
    dto.value.model.id === -1 ? watch(dto, updateFormData) : watch(dto.value, updateFormData);

    // expose computed properties.
    const sortedImages = computed(() => form.value.productImages?.sort((a, b) => a.sortOrder - b.sortOrder) ?? []);
    const isHighlightImage = computed(() => (image: ProductImageModel) => {
      return image.sortOrder === 1;
    });
    const availableProductCategories = computed(() =>
      availableProductCategoriesDto.value.list.map((c) => {
        return {
          id: c.model.id,
          nameDe: c.model.nameDe,
          nameFr: c.model.nameFr,
        };
      })
    );
    const availableActiveProducts = computed(() =>
      availableActiveProductsDto.value.list.map((c) => {
        return {
          id: c.model.id,
          nameDe: c.model.articleNumber + ": " + c.model.nameDe + " " + (c.model.subnameDe ?? ""),
          nameFr: c.model.articleNumber + ": " + c.model.nameFr + " " + (c.model.subnameFr ?? ""),
        };
      })
    );

    // expose methods.
    const isArchived = modelMixins.isProductArchived;
    const capitalizeFirstLetter = stringMixins.capitalizeFirstLetter;
    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    // Hack needed for Quill v2: https://github.com/primefaces/primevue/issues/5606#issuecomment-2093536386
    const editorModelBindingFix = (editor: any) => {
      if (!editor) return;
      editor.renderValue = function renderValue(value: any) {
        if (editor.quill) {
          if (value) {
            const delta = editor.quill.clipboard.convert({ html: value });
            editor.quill.setContents(delta, "silent");
          } else {
            editor.quill.setText("");
          }
        }
      }.bind(editor); // Bind needed for production build
    };
    const editorDeRef = ref();
    const editorFrRef = ref();
    watch(editorDeRef, editorModelBindingFix);
    watch(editorFrRef, editorModelBindingFix);

    // make initial fetch.
    onMounted(() => {
      productCategoryStore.getProductCategories(availableProductCategoriesDto.value);
      productStore.getActiveProducts(availableActiveProductsDto.value, null);
    });

    return {
      id,
      dto,
      form,
      formHelper,
      validator,
      imageUploadRef,
      sortedImages,
      isLoading,
      isLoadingRequest,
      isHighlightImage,
      availableColorThemes,
      availableProductCategories,
      availableActiveProducts,
      editorDeRef,
      editorFrRef,
      showToast,
      capitalizeFirstLetter,
      isArchived,
    };
  },
});
</script>

<style scoped lang="scss">
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
:deep(.p-fileupload-content) {
  display: flex;
  justify-content: flex-start;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
}

.color-preview {
  width: 20px;
  height: 20px;
  border: 1px solid grey;
  margin-right: 5px;

  &.stripes {
    background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, #fff 0, #fff 6px);
  }
}

.fileupload-entry {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  width: calc(25% - 8px);
  margin-bottom: 10px;
  position: relative;

  @media screen and (max-width: 1600px) {
    width: calc(33% - 8px);
  }
  @media screen and (max-width: 1200px) {
    width: calc(50% - 8px);
  }

  > * {
    padding: 10px;

    &:last-child {
      padding: 0;
    }
  }

  &.highlighted {
    border: 4px solid $successButtonBg;
  }

  &.disabled {
    opacity: 0.5;

    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid #ccc;
      border-top-color: transparent;
      animation: p-icon-spin 2s linear infinite;
    }
  }

  img {
    flex-shrink: 0;
    object-fit: contain;
    width: 100%;
    height: 300px;

    margin: 10px 0;
  }

  .fileupload-entry-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .fileupload-entry-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 10px;

    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      justify-content: flex-end;

      button {
        width: 50px;
      }
    }

    > * {
      width: calc(33%);
      display: flex;
      justify-content: center;
    }

    i {
      padding: 20px;
      cursor: pointer;

      &.disabled {
        cursor: default;
        opacity: 0.2;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      small {
        font-size: 0.7rem;
      }
    }
  }
}

.p-fileupload,
.button-panel,
.p-panel {
  margin-bottom: 40px;
}

// editor stylings
.p-editor-container {
  &.p-invalid {
    border: 1px solid $ff-primary-red;
  }
}

:deep(.ql-editor) {
  li[data-list=bullet] {
    &::before {
      display: none;
    }
  }
}

.ql-toolbar.ql-snow {
  ul li span.ql-ui {
    display: none;
  }
  .ql-formats {
    margin-right: 15px;
    width: fit-content;
    border-right: 1px solid $ff-controlpanel-lighter;
    padding-right: 15px;
  }
}
.archived {
  padding: 10px 0;
  margin-bottom: 30px;
}
</style>
