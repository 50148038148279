<template>
  <div class="inner">
    <div v-if="isSubmittedSuccessfully">
      <h2><Translate text="STOREFRONT_CONTACTFORM" /></h2>
      <p class="m-0">
        <strong><Translate text="STOREFRONT_MESSAGES_CONTACTFORM_SUCCESS_TITLE" /></strong>
      </p>
      <Message severity="success" :closable="false"><Translate text="STOREFRONT_MESSAGES_CONTACTFORM_SUCCESS_DESCRIPTION" /></Message>
    </div>
    <div v-else>
      <h2><Translate text="STOREFRONT_CONTACTFORM" /></h2>

      <p><Translate text="STOREFRONT_CONTACTFORM_DESCRIPTION" /></p>

      <div>
        <form @submit.prevent="" @change="validator.validateForm(form as ContactFormEntryFormData, dto)">
          <Panel :header="Translator.translate('STOREFRONT_CONTACTFORM_MESSAGE_HEADER')">
            <div class="form-row">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-pencil"></i>
                <InputText type="text" id="title" :disabled="dto.isLoading" v-model="form.title" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'title') }" />
                <label for="title"><Translate text="STOREFRONT_FORMS_CONTACTFORM_TITLE" /></label>
              </span>
              <small class="p-error" v-show="validator.hasValidationErrors(dto, 'title')"><Translate text="STOREFRONT_FORMS_CONTACTFORM_TITLE_VALIDATION" /></small>
            </div>

            <div class="form-row">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-pencil"></i>
                <Textarea id="text" :disabled="dto.isLoading" v-model="form.text" :rows="8" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'text') }" />
                <label for="text"><Translate text="STOREFRONT_FORMS_CONTACTFORM_TEXT" /></label>
              </span>
              <small class="p-error" v-show="validator.hasValidationErrors(dto, 'text')"><Translate text="STOREFRONT_FORMS_CONTACTFORM_TEXT_VALIDATION" /></small>
            </div>
          </Panel>
          <Panel :header="Translator.translate('STOREFRONT_CONTACTFORM_HEADER')">
            <div class="form-row split">
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-user"></i>
                  <InputText
                    type="text"
                    id="firstname"
                    name="firstname"
                    autocomplete="given-name"
                    :disabled="dto.isLoading"
                    v-model="form.firstname"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'firstname') }"
                  />
                  <label for="firstname"><Translate text="STOREFRONT_FORMS_CONTACTFORM_FIRSTNAME" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, 'firstname')"><Translate text="STOREFRONT_FORMS_CONTACTFORM_FIRSTNAME_VALIDATION" /></small>
              </div>

              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-user"></i>
                  <InputText
                    type="text"
                    id="surname"
                    name="surname"
                    autocomplete="family-name"
                    :disabled="dto.isLoading"
                    v-model="form.surname"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'surname') }"
                  />
                  <label for="surname"><Translate text="STOREFRONT_FORMS_CONTACTFORM_SURNAME" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, 'surname')"><Translate text="STOREFRONT_FORMS_CONTACTFORM_SURNAME_VALIDATION" /></small>
              </div>
            </div>

            <div class="form-row">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-at"></i>
                <InputText
                  type="text"
                  id="email"
                  name="email"
                  autocomplete="email"
                  :disabled="dto.isLoading"
                  v-model="form.email"
                  class="required"
                  :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'email') }"
                />
                <label for="email"><Translate text="STOREFRONT_FORMS_CONTACTFORM_EMAIL" /></label>
              </span>
              <small class="p-error" v-show="validator.hasValidationErrors(dto, 'email')"><Translate text="STOREFRONT_FORMS_CONTACTFORM_EMAIL_VALIDATION" /></small>
            </div>
          </Panel>
          <Panel :header="Translator.translate('STOREFRONT_CONTACTFORM_ADDRESS_HEADER')">
            <div class="form-row split">
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-home"></i>
                  <InputText
                    type="text"
                    id="street"
                    name="street"
                    autocomplete="address-line1"
                    :disabled="dto.isLoading"
                    v-model="form.street"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'street') }"
                  />
                  <label for="street"><Translate text="STOREFRONT_FORMS_CONTACTFORM_STREET" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, 'street')"><Translate text="STOREFRONT_FORMS_CONTACTFORM_STREET_VALIDATION" /></small>
              </div>
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-home"></i>
                  <InputText type="text" id="streetNumber" name="streetNumber" autocomplete="address-line2" :disabled="dto.isLoading" v-model="form.streetNumber" />
                  <label for="streetNumber"><Translate text="STOREFRONT_FORMS_CONTACTFORM_STREETNUMBER" /></label>
                </span>
              </div>
            </div>

            <div class="form-row split">
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-map-marker"></i>
                  <InputText
                    type="text"
                    id="zipCode"
                    name="zipCode"
                    autocomplete="postal-code"
                    :disabled="dto.isLoading"
                    v-model="form.zipCode"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'zipCode') }"
                  />
                  <label for="zipCode"><Translate text="STOREFRONT_FORMS_CONTACTFORM_ZIPCODE" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, 'zipCode')"><Translate text="STOREFRONT_FORMS_CONTACTFORM_ZIPCODE_VALIDATION" /></small>
              </div>
              <div>
                <span class="p-float-label p-input-icon-left">
                  <i class="pi pi-map"></i>
                  <InputText
                    type="text"
                    id="city"
                    name="city"
                    autocomplete="address-level2"
                    :disabled="dto.isLoading"
                    v-model="form.city"
                    class="required"
                    :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'city') }"
                  />
                  <label for="city"><Translate text="STOREFRONT_FORMS_CONTACTFORM_CITY" /></label>
                </span>
                <small class="p-error" v-show="validator.hasValidationErrors(dto, 'city')"><Translate text="STOREFRONT_FORMS_CONTACTFORM_CITY_VALIDATION" /></small>
              </div>
            </div>

            <div class="form-row">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-phone"></i>
                <InputText type="text" id="telephoneNumber" name="telephoneNumber" autocomplete="tel" :disabled="dto.isLoading" v-model="form.telephoneNumber" />
                <label for="telephoneNumber"><Translate text="STOREFRONT_FORMS_CONTACTFORM_TELEPHONENUMBER" /></label>
              </span>
            </div>

            <small class="required-explanation">* <Translate text="STOREFRONT_FORMS_REQUIRED" /></small>
          </Panel>
        </form>
      </div>

      <Message v-if="dto.hasError" severity="error">
        <p class="m-0">
          <strong><Translate text="STOREFRONT_MESSAGES_CONTACTFORM_FAILED_TITLE" /></strong>
        </p>
        <Translate text="STOREFRONT_MESSAGES_CONTACTFORM_FAILED_DESCRIPTION" />
      </Message>

      <div class="contactform-buttons">
        <FFButton @click="createContactFormEntry" :loading="dto.isLoading" :label="Translator.translate('STOREFRONT_CONTACTFORM_SUBMIT')" color="var(--ff-primary-black)"></FFButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useUserStatusStore } from "@/stores/userStatusStore";
import ProductCartEntry from "@/components/storefront/landingpage/products/ProductCartEntry.vue";
import ProductPrice from "@/components/shared/ProductPrice.vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { Validator } from "@/helpers/Validator";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import Translate from "@/components/shared/Translate.vue";
import { Translator } from "@/helpers/Translator";
import { useContactFormEntryStore } from "@/stores/contactFormEntryStore";
import { ContactFormEntryFormData } from "@/dtos/data/ContactFormEntryFormData";
import { ContactFormEntryDto } from "@/dtos/ContactFormEntryDtos";
import { ContactFormEntryModel } from "@/models/ContactFormEntryModel";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "StoreFrontContactForm",
  components: {
    ProductCartEntry,
    ProductPrice,
    FFButton,
    LoadingSkeleton,
    Translate,
  },
  methods: {
    createContactFormEntry(): void {
      const contactFormEntryStore = useContactFormEntryStore();

      this.form.shouldValidate = true;

      this.isSubmittedSuccessfully = false;

      // copy form values.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      this.validator
        .validateForm(this.form as ContactFormEntryFormData, this.dto)
        .then(() => contactFormEntryStore.createContactFormEntry(this.dto)) /* create order */
        .then(() => {
          // show success message.
          this.isSubmittedSuccessfully = true;
        });
    },
  },
  setup() {
    const userStatusStore = useUserStatusStore();

    const validator = new Validator();

    // set up form.
    const dto = ref(new ContactFormEntryDto(new ContactFormEntryModel()));
    const form = ref(new ContactFormEntryFormData(dto.value));
    const isSubmittedSuccessfully = ref(false);

    if (userStatusStore.isAuthenticated) {
      form.value.email = userStatusStore.userDto.model.username;

      if (userStatusStore.userDto.model.shippingAddress !== undefined && userStatusStore.userDto.model.shippingAddress !== null) {
        form.value.firstname = userStatusStore.userDto.model.shippingAddress.firstname;
        form.value.surname = userStatusStore.userDto.model.shippingAddress.surname;
        form.value.telephoneNumber = userStatusStore.userDto.model.shippingAddress.telephoneNumber;
        form.value.city = userStatusStore.userDto.model.shippingAddress.city;
        form.value.zipCode = userStatusStore.userDto.model.shippingAddress.zipCode;
        form.value.street = userStatusStore.userDto.model.shippingAddress.street;
        form.value.streetNumber = userStatusStore.userDto.model.shippingAddress.streetNumber;
      }
    }

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_CONTACTFORM")),
    });

    return {
      validator,
      dto,
      form,
      Translator,
      isSubmittedSuccessfully,
    };
  },
});
</script>

<style scoped lang="scss">
.p-panel {
  margin-top: 20px;

  :deep(.p-panel-header) {
    padding: 0.7rem 1.25rem;
  }

  :deep(.p-panel-icons) {
    display: inline-flex;
    align-items: center;

    label {
      width: auto;
    }
  }

  :deep(form) {
    .form-row {
      &.short {
        width: 50%;
      }
    }
  }
}

.contactform-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  .primary-button {
    background: $ff-primary-black;
  }
}
</style>
