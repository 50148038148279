<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="" @change="validator.validateForm(form as TranslationFormData, dto)">
      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <i class="pi pi-book"></i>
          <InputText type="text" id="translationKey" :disabled="true" v-model="form.translationKey" />
          <label for="translationKey">Key</label>
        </span>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <Checkbox type="text" id="isHtml" :disabled="dto.isLoading" v-model="form.isHtml" :binary="true" />
          <label for="isHtml">Als HTML anzeigen?</label>
        </span>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left p-float">
          <i class="pi pi-language required"></i>
          <label for="languageDe">Übersetzung Deutsch</label>
        </span>

        <Editor ref="editorDeRef" v-if="form.isHtml" v-model="form.languageDe" editorStyle="min-height: 250px;max-height:600px;overflow-y:scroll;font-size: 1em;" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'languageDe') }">
          <template v-slot:toolbar>
            <span class="ql-formats">
              <button v-tooltip.bottom="'Fett'" class="ql-bold"></button>
              <button v-tooltip.bottom="'Kursiv'" class="ql-italic"></button>
              <button v-tooltip.bottom="'Unterstreichen'" class="ql-underline"></button>
            </span>
            <span class="ql-formats">
              <button v-tooltip.bottom="'Bild'" class="ql-image"></button>
              <button v-tooltip.bottom="'Link'" class="ql-link"></button>
            </span>
            <span class="ql-formats">
              <button v-tooltip.bottom="'Nummerierte Liste'" class="ql-list" value="ordered"></button>
              <button v-tooltip.bottom="'Bullet Liste'" class="ql-list" value="bullet"></button>
              <button v-tooltip.bottom="'Überschrift'" class="ql-header" value="1"></button>
              <button v-tooltip.bottom="'Überschrift (klein)'" class="ql-header" value="3"></button>
            </span>
            <span class="ql-formats">
              <button v-tooltip.bottom="'Formatierung entfernen'" class="ql-clean"></button>
            </span>
          </template>
        </Editor>
        <InputText v-else type="text" v-model="form.languageDe" :disabled="dto.isLoading"></InputText>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'languageDe')">Übersetzung Deutsch ist ein Pflichtfeld.</small>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left p-float">
          <i class="pi pi-language required"></i>
          <label for="languageFr">Übersetzung Französisch</label>
        </span>
        <Editor ref="editorFrRef" v-if="form.isHtml" v-model="form.languageFr" editorStyle="min-height: 250px;max-height:600px;overflow-y:scroll;font-size: 1em;" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'languageFr') }">
          <template v-slot:toolbar>
            <span class="ql-formats">
              <button v-tooltip.bottom="'Fett'" class="ql-bold"></button>
              <button v-tooltip.bottom="'Kursiv'" class="ql-italic"></button>
              <button v-tooltip.bottom="'Unterstreichen'" class="ql-underline"></button>
            </span>
            <span class="ql-formats">
              <button v-tooltip.bottom="'Bild'" class="ql-image"></button>
              <button v-tooltip.bottom="'Link'" class="ql-link"></button>
            </span>
            <span class="ql-formats">
              <button v-tooltip.bottom="'Nummerierte Liste'" class="ql-list" value="ordered"></button>
              <button v-tooltip.bottom="'Bullet Liste'" class="ql-list" value="bullet"></button>
              <button v-tooltip.bottom="'Überschrift'" class="ql-header" value="1"></button>
              <button v-tooltip.bottom="'Überschrift (klein)'" class="ql-header" value="3"></button>
            </span>
            <span class="ql-formats">
              <button v-tooltip.bottom="'Formatierung entfernen'" class="ql-clean"></button>
            </span>
          </template>
        </Editor>
        <InputText v-else type="text" v-model="form.languageFr" :disabled="dto.isLoading"></InputText>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'languageFr')">Übersetzung Französisch ist ein Pflichtfeld.</small>
      </div>
    </form>

    <Message v-if="dto.hasError" severity="error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</Message>

    <div class="button-panel">
      <div></div>
      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
        <Button type="submit" label="Speichern" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="dto.isLoading"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>

  <Toast class="control-panel-toast">
    <template #message="slotProps">
      <div class="">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <Button class="confirm-button" :size="'small'" label="Zurück zur Liste" :severity="'success'" @click="formHelper.returnToList()"></Button>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { useTranslationStore } from "@/stores/translationStore";
import { TranslationFormData } from "@/dtos/data/TranslationFormData";
import { TranslationDto } from "@/dtos/TranslationDtos";
import { useToast } from "primevue/usetoast";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { ToastMessageOptions } from "primevue/toast";
import { ITranslationModel } from "@/models/interfaces/ITranslationModel";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { ObjectHelper } from "@/helpers/ObjectHelper";

/**
 * A shared component used for editing (and creating) translations.
 */
export default defineComponent({
  name: "TranslationsCreateEdit",
  props: {
    id: Number,
    dto: {
      type: TranslationDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  methods: {
    async onSave() {
      const store = useTranslationStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // validate before save.
      this.validator.validateForm(this.form as TranslationFormData, this.dto).then(() => {
        if (this.form.isExistingOrNew()) {
          // send PUT request and show toast if successful.
          store.updateTranslation(this.dto as TranslationDto).then(async () => {
            // refresh list and return to list.
            await store.getTranslations(store.listDto);

            this.showToast({
              life: 5000,
              severity: "success",
              summary: "Erfolgreich gespeichert",
              detail: "Die Übersetzung wurde erfolgreich aktualisiert.",
            });
          });
        }
      });
    },
  },
  setup(props) {
    const toast = useToast();

    const validator = new Validator<ITranslationModel>();
    const formHelper = new FormHelper("/controlpanel/translations");

    // get entity from props.
    const { id, dto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new TranslationFormData(dto.value));

    // setup form watchers.
    watch(dto.value, (newValue) => {
      form.value = new TranslationFormData(newValue);
    });

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    // Hack needed for Quill v2: https://github.com/primefaces/primevue/issues/5606#issuecomment-2093536386
    const editorModelBindingFix = (editor: any) => {
      if (!editor) return;
      editor.renderValue = function renderValue(value: any) {
        if (editor.quill) {
          if (value) {
            const delta = editor.quill.clipboard.convert({ html: value });
            editor.quill.setContents(delta, "silent");
          } else {
            editor.quill.setText("");
          }
        }
      }.bind(editor); // Bind needed for production build
    };
    const editorDeRef = ref();
    const editorFrRef = ref();
    watch(editorDeRef, editorModelBindingFix);
    watch(editorFrRef, editorModelBindingFix);

    return {
      id,
      dto,
      form,
      formHelper,
      editorDeRef,
      editorFrRef,
      validator,
      showToast,
    };
  },
});
</script>

<style scoped lang="scss">
// editor stylings
.p-editor-container {
  &.p-invalid {
    border: 1px solid $ff-primary-red;
  }
}

:deep(.ql-editor) {
  li[data-list=bullet] {
    &::before {
      display: none;
    }
  }
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
  width: fit-content;
  border-right: 1px solid $ff-controlpanel-lighter;
  padding-right: 15px;
}
</style>
