<template>
  <h1>Dashboard</h1>

  <div class="menu-bars mt-2">
    <Menubar :model="dateMenuItems" />
    <Menubar :model="compareMenuItems" />
  </div>

  <form v-show="datePeriodType === DatePeriodType.CUSTOM">
    <div class="form-row split custom-dates">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-calendar"></i>
        <Calendar id="createdAt" :loading="isAnythingLoading" date-format="dd.mm.yy" showTime hourFormat="24" v-model="form.fromDate" />
        <label for="createdAt">Daten von</label>
      </span>
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-calendar"></i>
        <Calendar id="createdAt" :loading="isAnythingLoading" date-format="dd.mm.yy" showTime hourFormat="24" v-model="form.toDate" />
        <label for="createdAt">Daten bis</label>
      </span>
      <Button label="Aktualisieren" @click="fetchReports"></Button>
    </div>
  </form>

  <!-- OREDES Reporting -->
  <h3 class="mt-6 mb-2">Auswertung Bestellungen</h3>

  <div class="reporting" v-if="!isOrdersReportLoading">
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-users"></i>Bestellungen Total</strong><small>(in dieser Periode)</small>
        <p>{{ ordersReport.amountOfOrders }}</p>
      </template>
    </Card>
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Anzahl Sachets</strong><small>(in dieser Periode)</small>
        <p>{{ ordersReport.amountOfOrderedSachets }}</p>
      </template>
    </Card>
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Anzahl Versendete Produkte</strong><small>(in dieser Periode)</small>
        <p>{{ ordersReport.amountOfSentProducts }} / {{ ordersReport.amountOfOrderedProducts }}</p>
      </template>
    </Card>
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-user-minus"></i>Stornierte Bestellungen</strong><small>(in dieser Periode)</small>
        <p>{{ ordersReport.amountOfCanceledOrders }}</p>
      </template>
    </Card>

    <Card class="w-100 content-center">
      <template #content>
        <i class="pi pi-copy float-right" @click="(e) => copyCanvasToClipboard(e, sentProductsChartRef)"></i>
        <strong> <i class="pi pi-gift"></i>Bestellungsverlauf</strong><small>(in dieser Periode)</small>
        <Chart ref="sentProductsChartRef" v-if="chartOptions" type="line" :data="ordersChartData" :options="chartOptions" />
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>

  <!-- Products Reporting -->
  <h3 class="mt-6 mb-2">Auswertung Produkte</h3>

  <div class="reporting" v-if="!isProductsReportLoading">
    <Card class="w-50">
      <template #content>
        <strong> <i class="pi pi-users"></i>Produkte Total</strong><small class="system">(systemweit)</small>
        <p>{{ productsReport.amountOfProducts }}</p>
      </template>
    </Card>
    <Card class="w-50">
      <template #content>
        <strong> <i class="pi pi-user-minus"></i>Aktive Produkte</strong><small class="system">(systemweit)</small>
        <p>{{ productsReport.amountOfActiveProducts }}</p>
      </template>
    </Card>

    <Card class="w-50">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Bestsellers</strong><small>(in dieser Periode)</small>

        <DataTable :value="productsReport.bestRankingProducts" dataKey="product.id" size="small" stripedRows class="mt-2">
          <template #empty> Es wurden keine Produkte verkauft in dieser Periode. </template>
          <Column field="amountSold" header="Anzahl Verkauft"></Column>
          <Column field="product.nameDe" header="Produkt">
            <template #body="slotProps">
              <a :href="'/controlpanel/products/' + slotProps.data.product.id">{{ slotProps.data.product.nameDe }} {{ slotProps.data.product.subnameDe }}</a>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
    <Card class="w-50">
      <template #content>
        <i class="pi pi-copy float-right" @click="(e) => copyCanvasToClipboard(e, bestsellerProductsPieChartRef)"></i>
        <strong> <i class="pi pi-gift"></i>Bestsellers (Verteilung)</strong><small>(in dieser Periode)</small>
        <Chart ref="bestsellerProductsPieChartRef" v-if="chartOptions" type="pie" :data="bestsellerProductsPieChartData" :options="pieChartOptions" class="big" />
      </template>
    </Card>

    <Card class="w-100 content-center">
      <template #content>
        <i class="pi pi-copy float-right" @click="(e) => copyCanvasToClipboard(e, sentProductsChartRef)"></i>
        <strong> <i class="pi pi-gift"></i>Produkteversand</strong><small>(in dieser Periode)</small>
        <Chart ref="sentProductsChartRef" v-if="chartOptions" type="line" :data="sentProductsChartData" :options="chartOptions" />
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>

  <!-- USERS Reporting -->
  <h3 class="mt-6 mb-2">Auswertung Benutzer</h3>

  <div class="reporting" v-if="!isUsersReportLoading">
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-users"></i>Neue Benutzer</strong><small>(in dieser Periode)</small>
        <p>{{ usersReport.amountOfNewUsers }}</p>
      </template>
    </Card>
    <div class="w-75"></div>

    <Card class="w-100 content-center">
      <template #content>
        <i class="pi pi-copy float-right" @click="(e) => copyCanvasToClipboard(e, newUsersChartRef)"></i>
        <strong> <i class="pi pi-gift"></i>Neue Benutzer (Verlauf)</strong><small>(in dieser Periode)</small>
        <Chart ref="newUsersChartRef" v-if="chartOptions" type="line" :data="newUsersChartData" :options="chartOptions" />
      </template>
    </Card>

    <Card class="w-100 mb-8">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Top Buyers</strong><small>(in dieser Periode)</small>
        <DataTable :value="usersReport.bestRankingBuyers" dataKey="user.id" size="small" stripedRows class="mt-2">
          <template #empty> Es wurden keine Produkte verkauft in dieser Periode. </template>
          <Column field="totalSachetsSpent" header="Anzahl Sachets bestellt"></Column>
          <Column field="user.displayName" header="Benutzer">
            <template #body="slotProps">
              <a :href="'/controlpanel/users/' + slotProps.data.user.id">{{ slotProps.data.user.displayName }} ({{ slotProps.data.user.username }})</a>
            </template>
          </Column>
          <Column field="lastOrderedAt" header="Letzte Bestellung">
            <template #body="slotProps"> <DateDisplay :date-string="slotProps.data.lastOrderedAt" :with-time="true"></DateDisplay> Uhr </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useReportingStore } from "@/stores/reportingStore";
import { ProductsReport } from "@/dtos/data/reports/ProductsReport";
import { AxiosResponse } from "axios";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";
import { DashboardFormData } from "@/dtos/data/DashboardFormData";
import { MenuItem } from "primevue/menuitem";
import { DatePeriodType } from "@/enums/DatePeriodType";
import { DatePeriodComparisonType } from "@/enums/DatePeriodComparisonType";
import { storeToRefs } from "pinia";
import { OrdersReport } from "@/dtos/data/reports/OrdersReport";
import { UsersReport } from "@/dtos/data/reports/UsersReport";
import { SecurityReport } from "@/dtos/data/reports/SecurityReport";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { DateHelper } from "@/helpers/DateHelper";
import Chart from "primevue/chart";
import { FormHelper } from "@/helpers/FormHelper";

export default defineComponent({
  name: "Dashboard",
  components: { DateDisplay },
  methods: {
    copyCanvasToClipboard(event: Event, chart: any) {
      const base64 = chart.getBase64Image();
      const blob = this.formHelper.base64ToBlob(base64.split(",")[1], "image/png");

      navigator.clipboard
        .write([
          new ClipboardItem({
            "image/png": blob,
          }),
        ])
        .then(() => {
          const caller = event.target as HTMLElement;
          if (caller) {
            caller.classList.remove("pi-copy");
            caller.classList.add("pi-check", "p-disabled");
            setTimeout(() => {
              caller.classList.remove("pi-check", "p-disabled");
              caller.classList.add("pi-copy");
            }, 1000);
          }
        });
    },
  },
  setup() {
    const reportingStore = useReportingStore();

    const formHelper = new FormHelper("/controlpanel");

    const { isOrdersReportLoading, isProductsReportLoading, isSecurityReportLoading, isUsersReportLoading } = storeToRefs(reportingStore);
    const { datePeriodType } = storeToRefs(reportingStore);

    const sentProductsChartData = ref();
    const ordersChartData = ref();
    const bestsellerProductsPieChartData = ref();
    const newUsersChartData = ref();

    const chartOptions = ref();
    const pieChartOptions = ref();

    const sentProductsChartRef = ref<InstanceType<typeof Chart>>();
    const ordersChartRef = ref<InstanceType<typeof Chart>>();
    const bestsellerProductsPieChartRef = ref<InstanceType<typeof Chart>>();
    const newUsersChartRef = ref<InstanceType<typeof Chart>>();

    const ordersReport = ref(new OrdersReport());
    const productsReport = ref(new ProductsReport());
    const usersReport = ref(new UsersReport());
    const securityReport = ref(new SecurityReport());

    const datePeriodComparisonType = ref(DatePeriodComparisonType.NONE);
    const form = ref(new DashboardFormData());

    const isAnythingLoading = computed(() => isOrdersReportLoading.value || isProductsReportLoading.value || isSecurityReportLoading.value || isUsersReportLoading.value);

    const fetchReports = () => {
      // get reporting data for products.
      reportingStore.getProductsReport(form.value.fromDate, form.value.toDate).then((response: AxiosResponse<ReportingViewModel>) => {
        productsReport.value = response.data.productsReport;
        sentProductsChartData.value = setProductsTimelineChartData();
        bestsellerProductsPieChartData.value = setBestsellerProductsPieChartData();
      });

      // get reporting data for orders.
      reportingStore.getOrdersReport(form.value.fromDate, form.value.toDate).then((response: AxiosResponse<ReportingViewModel>) => {
        ordersReport.value = response.data.ordersReport;
        ordersChartData.value = setOrdersTimelineChartData();
      });

      // get reporting data for users.
      reportingStore.getUsersReport(form.value.fromDate, form.value.toDate).then((response: AxiosResponse<ReportingViewModel>) => {
        usersReport.value = response.data.usersReport;
        newUsersChartData.value = setnewUsersTimelineChartData();
      });
    };

    const changePeriod = (period: DatePeriodType) => {
      datePeriodType.value = period;

      const today = DateHelper.stripTime(new Date());

      switch (period) {
        case DatePeriodType.DAY:
          form.value.fromDate = today;
          form.value.toDate = DateHelper.getEndOfDay(today);
          fetchReports();
          break;
        case DatePeriodType.WEEK:
          form.value.fromDate = DateHelper.getFirstDayOfWeek(today);
          form.value.toDate = DateHelper.getLastDayOfWeek(today);
          fetchReports();
          break;
        case DatePeriodType.MONTH:
          form.value.fromDate = DateHelper.getFirstDayOfMonth(today);
          form.value.toDate = DateHelper.getLastDayOfMonth(today);
          fetchReports();
          break;
        case DatePeriodType.QUARTER:
          form.value.fromDate = DateHelper.getFirstDayOfQuarter(today);
          form.value.toDate = DateHelper.getLastDayOfQuarter(today);
          fetchReports();
          break;
        case DatePeriodType.YEAR:
          form.value.fromDate = new Date(today.getFullYear(), 0, 1);
          form.value.toDate = new Date(today.getFullYear(), 11, 31);
          fetchReports();
          break;
        case DatePeriodType.CUSTOM:
          form.value.fromDate = DateHelper.getStartOfDay(today);
          form.value.toDate = DateHelper.getEndOfDay(today);
          // do nothing here; input fields handle that.
          break;
      }
    };

    const changeComparison = (comparison: DatePeriodComparisonType) => {
      datePeriodComparisonType.value = comparison;

      switch (comparison) {
        case DatePeriodComparisonType.NONE:
          break;
        case DatePeriodComparisonType.WITH_PREVIOUS_PERIOD:
          break;
      }

      fetchReports();
    };

    const dateMenuItems = ref([
      {
        label: "Heute",
        icon: "pi pi-clock",
        class: () => (datePeriodType.value === DatePeriodType.DAY ? "active" : ""),
        command: () => changePeriod(DatePeriodType.DAY),
      },
      {
        label: "Diese Woche",
        icon: "pi pi-calendar-clock",
        class: () => (datePeriodType.value === DatePeriodType.WEEK ? "active" : ""),
        command: () => changePeriod(DatePeriodType.WEEK),
      },
      {
        label: "Diesen Monat",
        icon: "pi pi-calendar",
        class: () => (datePeriodType.value === DatePeriodType.MONTH ? "active" : ""),
        command: () => changePeriod(DatePeriodType.MONTH),
      },
      {
        label: "Dieses Quartal",
        icon: "pi pi-calendar",
        class: () => (datePeriodType.value === DatePeriodType.QUARTER ? "active" : ""),
        command: () => changePeriod(DatePeriodType.QUARTER),
      },
      {
        label: "Dieses Jahr",
        icon: "pi pi-calendar",
        class: () => (datePeriodType.value === DatePeriodType.YEAR ? "active" : ""),
        command: () => changePeriod(DatePeriodType.YEAR),
      },
      {
        label: "Individuell",
        icon: "pi pi-cog",
        class: () => (datePeriodType.value === DatePeriodType.CUSTOM ? "active" : ""),
        command: () => changePeriod(DatePeriodType.CUSTOM),
      },
    ] as MenuItem[]);

    const compareMenuItems = ref([
      {
        label: "Vergleichen",
        icon: "pi pi-arrow-right-arrow-left",
        disabled: true /* TODO not implemented */,
        items: [
          {
            label: "Mit letzter Periode",
            icon: "pi pi-history",
            command: () => changeComparison(DatePeriodComparisonType.WITH_PREVIOUS_PERIOD),
          },
          {
            label: "Nicht vergleichen",
            icon: "pi pi-times",
            command: () => changeComparison(DatePeriodComparisonType.NONE),
          },
        ],
      },
      {
        label: "Aktiv",
        icon: "pi pi-check",
        class: "not-clickable",
        visible: () => datePeriodComparisonType.value === DatePeriodComparisonType.WITH_PREVIOUS_PERIOD,
      },
    ] as MenuItem[]);

    // =========== CHARTS ======================
    const setChartOptions = () => {
      return {
        scales: {
          x: {
            offset: true,
          },
          y: {
            ticks: {
              precision: 0 /* only whole numbers */,
            },
          },
        },
      };
    };
    const setPieChartOptions = () => {
      return {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
      };
    };

    const MAX_POINTS_COUNT = 100;
    const DEFAULT_RADIUS = 3;

    // set up chart data.
    const setOrdersTimelineChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: ordersReport.value.ordersTimeline.map((d) => d.date),
        datasets: [
          {
            label: "Bestellungen an diesem Tag",
            data: ordersReport.value.ordersTimeline.map((d) => d.amount),
            borderColor: documentStyle.getPropertyValue("--red-600"),
            backgroundColor: documentStyle.getPropertyValue("--red-600"),
            tension: 0.4,
            radius: () => (ordersReport.value.ordersTimeline.length < MAX_POINTS_COUNT ? DEFAULT_RADIUS : 0),
          },
          {
            label: "Umsatz in Sachets an diesem Tag",
            data: ordersReport.value.ordersTimeline.map((d) => d.amountOfSachets),
            borderColor: documentStyle.getPropertyValue("--green-600"),
            backgroundColor: documentStyle.getPropertyValue("--green-600"),
            tension: 0.4,
            radius: () => (ordersReport.value.ordersTimeline.length < MAX_POINTS_COUNT ? DEFAULT_RADIUS : 0),
          },
        ],
      };
    };
    const setProductsTimelineChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: productsReport.value.sentProductsTimeline.map((d) => d.date),
        datasets: [
          {
            label: "Versendete Produkte an diesem Tag",
            data: productsReport.value.sentProductsTimeline.map((d) => d.amount),
            borderColor: documentStyle.getPropertyValue("--red-600"),
            backgroundColor: documentStyle.getPropertyValue("--red-600"),
            radius: () => (productsReport.value.sentProductsTimeline.length < MAX_POINTS_COUNT ? DEFAULT_RADIUS : 0),
            tension: 0.4,
          },
        ],
      };
    };
    const setBestsellerProductsPieChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: productsReport.value.bestRankingProducts.map((x) => `${x.product.nameDe} ${x.product.subnameDe}`),
        datasets: [
          {
            label: "Anzahl",
            data: productsReport.value.bestRankingProducts.map((x) => x.amountSold),
            backgroundColor: [
              documentStyle.getPropertyValue("--red-600"),
              documentStyle.getPropertyValue("--red-300"),
              documentStyle.getPropertyValue("--green-600"),
              documentStyle.getPropertyValue("--green-300"),
              documentStyle.getPropertyValue("--blue-600"),
              documentStyle.getPropertyValue("--blue-300"),
              documentStyle.getPropertyValue("--yellow-600"),
              documentStyle.getPropertyValue("--yellow-300"),
              documentStyle.getPropertyValue("--gray-600"),
              documentStyle.getPropertyValue("--gray-500"),
              documentStyle.getPropertyValue("--gray-400"),
              documentStyle.getPropertyValue("--gray-300"),
              documentStyle.getPropertyValue("--gray-200"),
              documentStyle.getPropertyValue("--gray-100"),
            ],
          },
        ],
      };
    };
    const setnewUsersTimelineChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: usersReport.value.newUsersTimeline.map((d) => d.date),
        datasets: [
          {
            label: "Neukunden an diesem Tag",
            data: usersReport.value.newUsersTimeline.map((d) => d.amount),
            borderColor: documentStyle.getPropertyValue("--red-600"),
            backgroundColor: documentStyle.getPropertyValue("--red-600"),
            radius: () => (usersReport.value.newUsersTimeline.length < MAX_POINTS_COUNT ? DEFAULT_RADIUS : 0),
            tension: 0.4,
          },
        ],
      };
    };

    // make initial API calls.
    onMounted(() => {
      chartOptions.value = setChartOptions();
      pieChartOptions.value = setPieChartOptions();

      changePeriod(datePeriodType.value);

      fetchReports();
    });

    return {
      formHelper,
      form,
      sentProductsChartRef,
      ordersChartRef,
      bestsellerProductsPieChartRef,
      newUsersChartRef,
      productsReport,
      ordersReport,
      usersReport,
      securityReport,
      chartOptions,
      pieChartOptions,
      sentProductsChartData,
      newUsersChartData,
      ordersChartData,
      bestsellerProductsPieChartData,
      fetchReports,
      dateMenuItems,
      compareMenuItems,
      DatePeriodType,
      DatePeriodComparisonType,
      datePeriodType,
      datePeriodComparisonType,
      isAnythingLoading,
      isOrdersReportLoading,
      isUsersReportLoading,
      isProductsReportLoading,
      isSecurityReportLoading,
    };
  },
});
</script>

<style scoped lang="scss">
.reporting {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 20px;

  > div {
    &.w-100 {
      width: 100%;
    }
    &.w-50 {
      width: calc(50% - 10px);
    }
    &.w-33 {
      width: calc(33% - 13px);
    }
    &.w-25 {
      width: calc(25% - 15px);
    }
    &.w-75 {
      width: calc(75% - 15px);
    }

    :deep(.p-card-content) {
      padding: 0;
    }

    i {
      margin-right: 10px;
    }

    p {
      margin: 5px 0;
    }

    :deep(.p-card-body) {
      canvas {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .p-chart {
      height: 20rem;

      &.big {
        height: 30rem;
      }
    }
  }

  .p-card {
    border: 1px solid $ff-controlpanel-lighter;

    .p-card-content > p {
      font-size: 2rem;
    }

    a {
      text-decoration: underline;
      color: $ff-primary-red;
    }

    .list {
      display: flex;
      flex-direction: column;

      > span {
        border-bottom: 1px solid $ff-controlpanel-lighter;
        padding: 10px 5px;

        span {
          margin-right: 20px;
        }
      }
    }

    .float-right {
      float: right;
      cursor: pointer;

      &.p-disabled {
        color: $ff-primary-red;
      }
    }

    small {
      color: $ff-gold-package;
      display: block;

      &.system {
        color: $ff-blue-stripe-package;
      }
    }
  }
}

.menu-bars {
  display: flex;

  > div:last-child {
    flex-grow: 1;
  }

  :deep(.p-menubar) {
    .p-menuitem {
      :hover {
        text-decoration: none;
      }

      &.active {
        a {
          background-color: $ff-controlpanel-dark;

          span {
            font-weight: bold;
            color: $ff-primary-white;

            &.p-menuitem-icon {
              color: $ff-primary-red;
            }
          }
        }
      }
    }
  }
}

.custom-dates {
  column-gap: 10px !important;

  > span {
    width: 200px !important;
  }
}
</style>
