<template>
  <nav>
    <CustomMenuBar :items="generalItems" :is-exact-active-class="true" :is-active-class="false"></CustomMenuBar>
    <h3>Navigation</h3>
    <CustomMenuBar :items="navigationItems" :is-exact-active-class="true" :is-active-class="false"></CustomMenuBar>
    <h3>Verwaltung</h3>
    <CustomMenuBar :items="managementItems" :is-exact-active-class="false" :is-active-class="true"></CustomMenuBar>
    <h3>System</h3>
    <CustomMenuBar :items="evaluationItems" :is-exact-active-class="false" :is-active-class="true"></CustomMenuBar>
  </nav>
</template>

<script lang="ts">
import { MenuItem } from "primevue/menuitem";
import { defineComponent, ref } from "vue";
import CustomMenuBar from "@/components/shared/CustomMenuBar.vue";

/**
 * Component used to render the navigation items in the Control Panel.
 */
export default defineComponent({
  name: "ControlPanelNavigation",
  components: {
    CustomMenuBar,
  },
  setup() {
    const generalItems = ref([
      {
        label: "Zur Hauptseite",
        icon: "pi pi-external-link",
        url: "/",
        isExact: true,
      },
    ] as MenuItem[]);

    const navigationItems = ref([
      {
        label: "Dashboard",
        icon: "pi pi-home",
        url: "/controlpanel",
        isExact: true,
      },
    ] as MenuItem[]);

    const managementItems = ref([
      {
        label: "Produkte",
        icon: "pi pi-box",
        url: "/controlpanel/products",
        isExact: false,
      },
      {
        label: "Produktekategorien",
        icon: "pi pi-th-large",
        url: "/controlpanel/productcategories",
        isExact: false,
      },
      {
        label: "Benutzer",
        icon: "pi pi-users",
        url: "/controlpanel/users",
        isExact: false,
      },
      {
        label: "Bestellungen",
        icon: "pi pi-shopping-cart",
        url: "/controlpanel/orders",
        isExact: false,
      },
      {
        label: "Übersetzungen",
        icon: "pi pi-language",
        url: "/controlpanel/translations",
        isExact: false,
      },
      {
        label: "Sliders",
        icon: "pi pi-bars",
        url: "/controlpanel/sliders",
        isExact: false,
      },
      {
        label: "Coupons",
        icon: "pi pi-ticket",
        url: "/controlpanel/coupons",
        isExact: false,
      },
      {
        label: "Kontaktformular-Einträge",
        icon: "pi pi-file-edit",
        url: "/controlpanel/contactformentries",
        isExact: false,
      },
    ] as MenuItem[]);

    const evaluationItems = ref([
      {
        label: "Länder",
        icon: "pi pi-globe",
        url: "/controlpanel/countries",
        isExact: false,
      },
      {
        label: "Log-Einträge",
        icon: "pi pi-server",
        url: "/controlpanel/logentries",
        isExact: false,
      },
    ] as MenuItem[]);

    return {
      generalItems,
      navigationItems,
      managementItems,
      evaluationItems,
    };
  },
});
</script>

<style scoped lang="scss">
nav {
  position: fixed;
  top: 100px;
  left: 0;
  min-width: 220px;
  height: 100%;
  background-color: grey;
  display: flex;
  flex-direction: column;
  background-color: $ff-controlpanel-dark;
  color: $ff-primary-white;
  padding: 10px;

  h3 {
    margin: 5px 0;
    color: $ff-controlpanel-medium;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  nav {
    position: relative;
    top: 0;
  }
}
</style>
