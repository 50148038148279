<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="" @change="validator.validateForm(form as SliderFormData, dto)">
      <div class="active-panel">
        <InputSwitch id="active" v-model="form.isActive" />
        <label for="active" class="p-label">{{ form.isActive ? "Aktiv" : "Inaktiv" }}</label>
      </div>

      <div class="form-row split">
        <div>
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="titleDe" :disabled="dto.isLoading" v-model="form.titleDe" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'titleDe') }" />
            <label for="titleDe">Titel Deutsch</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'titleDe')">Titel Deutsch ist ein Pflichtfeld.</small>
        </div>

        <div>
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="titleFr" :disabled="dto.isLoading" v-model="form.titleFr" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'titleFr') }" />
            <label for="titleFr">Titel Französisch</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'titleFr')">Titel Französisch ist ein Pflichtfeld.</small>
        </div>
      </div>

      <div class="form-row split">
        <div>
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="textDe" :disabled="dto.isLoading" v-model="form.textDe" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'textDe') }" />
            <label for="textDe">Text Deutsch</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'textDe')">Text Deutsch ist ein Pflichtfeld.</small>
        </div>

        <div>
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="textFr" :disabled="dto.isLoading" v-model="form.textFr" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'textFr') }" />
            <label for="textFr">Text Französisch</label>
          </span>
          <small class="p-error" v-show="validator.hasValidationErrors(dto, 'textFr')">Text Französisch ist ein Pflichtfeld.</small>
        </div>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <i class="pi pi-user"></i>
          <InputText type="text" id="linkDe" :disabled="dto.isLoading" v-model="form.linkDe" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'linkDe') }" />
          <label for="linkDe">Link Deutsch</label>
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'linkDe')">Link Deutsch ist ein Pflichtfeld.</small>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <i class="pi pi-user"></i>
          <InputText type="text" id="linkFr" :disabled="dto.isLoading" v-model="form.linkFr" class="required" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'linkFr') }" />
          <label for="linkFr">Link Französisch</label>
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'linkFr')">Link Französisch ist ein Pflichtfeld.</small>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <label for="image" class="p-float">Bild</label>
          <Image v-if="form.image" id="image" :src="form.image" preview></Image>

          <FileUpload
            mode="basic"
            name="demo[]"
            accept="image/*"
            :customUpload="true"
            :maxFileSize="2 /*MB*/ * 1024 * 1024"
            @uploader="onUpload"
            :auto="true"
            :disabled="dto.isLoading"
            chooseLabel="Bild wählen..."
          />
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'image')">Das Bild ist ein Pflichtfeld.</small>
      </div>

      <Message v-if="dto.hasError" severity="error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</Message>
    </form>

    <div class="button-panel">
      <Button type="button" label="Löschen" :severity="'danger'" icon="pi pi-trash" @click="onDelete($event)"></Button>

      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
        <Button type="submit" label="Speichern" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="dto.isLoading"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>

  <ConfirmDialog></ConfirmDialog>
  <Toast class="control-panel-toast">
    <template #message="slotProps">
      <div class="">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <Button class="confirm-button" :size="'small'" label="Zurück zur Liste" :severity="'success'" @click="formHelper.returnToList()"></Button>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { SliderDto, SliderListDto } from "@/dtos/SliderDtos";
import { SliderFormData } from "@/dtos/data/SliderFormData";
import { useSliderStore } from "@/stores/sliderStore";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { ISliderModel } from "@/models/interfaces/ISliderModel";
import { ToastMessageOptions } from "primevue/toast";
import { FileUploadUploaderEvent } from "primevue/fileupload";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { ObjectHelper } from "@/helpers/ObjectHelper";

export default defineComponent({
  name: "SlidersCreateEdit",
  props: {
    id: Number,
    dto: {
      type: SliderDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  methods: {
    async onSave() {
      const store = useSliderStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // validate before save.
      this.validator.validateForm(this.form as SliderFormData, this.dto).then(() => {
        if (this.form.isNew()) {
          // send POST request and redirect if success.
          store.createSlider(this.dto as SliderDto).then(async () => {
            this.formHelper.returnToList();
          });
        } else {
          // send PUT request and show toast if successful.
          store.updateSlider(this.dto as SliderDto).then(async () => {
            this.showToast({
              life: 5000,
              severity: "success",
              summary: "Erfolgreich gespeichert",
              detail: "Der Slider wurde erfolgreich aktualisiert.",
            });
          });
        }
      });
    },
    async onDelete(event: Event) {
      const store = useSliderStore();

      this.formHelper.promptForDeletion(
        event,
        () => {
          // send DELETE request and redirect if success.
          store.deleteSlider(this.dto as SliderDto).then(async () => {
            // refresh list.
            await store.getSliders(new SliderListDto([]));

            // and return to list.
            this.formHelper.returnToList();
          });
        },
        () => {}
      );
    },
    async onUpload(event: FileUploadUploaderEvent) {
      const files = event.files as File[];
      if (files !== undefined && files.length === 1) {
        const base64 = await this.formHelper.blobImageToBase64(files[0]);
        this.form.image = base64;

        // force validation because the image is not part of the form.
        this.validator.validateForm(this.form as SliderFormData, this.dto);
      }
    },
  },
  setup(props) {
    const toast = useToast();

    const validator = new Validator<ISliderModel>();
    const formHelper = new FormHelper("/controlpanel/sliders");

    // get entity from props.
    const { id, dto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new SliderFormData(dto.value));

    // setup form watchers.
    const updateFormData = (newValue: SliderDto) => {
      if (newValue?.model !== null) form.value = new SliderFormData(newValue);
    };
    dto.value.model.id === -1 ? watch(dto, updateFormData) : watch(dto.value, updateFormData);

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      id,
      dto,
      form,
      formHelper,
      validator,
      showToast,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-image) {
  width: fit-content !important;
}
</style>
