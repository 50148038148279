import { ReportingApiController } from "@/api/ReportingApiController";
import { ReportingState } from "@/dtos/state/ReportingState";
import { DatePeriodType } from "@/enums/DatePeriodType";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";

const apiController = new ReportingApiController();

export const useReportingStore = defineStore("reporting", {
  state: (): ReportingState => ({
    datePeriodType: DatePeriodType.DAY,
    isOrdersReportLoading: false,
    isProductsReportLoading: false,
    isUsersReportLoading: false,
    isSecurityReportLoading: false,
  }),
  actions: {
    /* -------------------------------------------
     * Get all Products Reportings from API.
     * -------------------------------------------- */
    async getProductsReport(from: Date, to: Date): Promise<AxiosResponse<ReportingViewModel>> {
      this.isProductsReportLoading = true;

      return apiController.getProductsReport(from, to).finally(() => {
        this.isProductsReportLoading = false;
      });
    },

    /* -------------------------------------------
     * Get all Orders Reportings from API.
     * -------------------------------------------- */
    async getOrdersReport(from: Date, to: Date): Promise<AxiosResponse<ReportingViewModel>> {
      this.isOrdersReportLoading = true;

      return apiController.getOrdersReport(from, to).finally(() => {
        this.isOrdersReportLoading = false;
      });
    },

    /* -------------------------------------------
     * Get all Users Reportings from API.
     * -------------------------------------------- */
    async getUsersReport(from: Date, to: Date): Promise<AxiosResponse<ReportingViewModel>> {
      this.isUsersReportLoading = true;

      return apiController.getUsersReport(from, to).finally(() => {
        this.isUsersReportLoading = false;
      });
    },

    /* -------------------------------------------
     * Get all Security Reportings from API.
     * -------------------------------------------- */
    async getSecurityReport(from: Date, to: Date): Promise<AxiosResponse<ReportingViewModel>> {
      this.isSecurityReportLoading = true;

      return apiController.getSecurityReport(from, to).finally(() => {
        this.isSecurityReportLoading = false;
      });
    },
  },
});
