import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { toRaw } from "vue";
import { LogEntryModel } from "@/models/LogEntryModel";
import { LogEntryDto } from "../LogEntryDtos";
import { IContactFormEntryModel } from "@/models/interfaces/IContactFormModel";
import { ContactFormEntryDto } from "../ContactFormEntryDtos";
import { ContactFormEntryModel } from "@/models/ContactFormEntryModel";

export class ContactFormEntryFormData extends BaseFormData<IContactFormEntryModel> implements IContactFormEntryModel {
  public firstname: string;
  public surname: string;
  public telephoneNumber?: string | null;
  public email: string;
  public street: string;
  public streetNumber?: string | null;
  public zipCode: number | null;
  public city: string;
  public title: string;
  public text: string;

  public originalData: ContactFormEntryModel;

  constructor(dto: ContactFormEntryDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.firstname = dto.model.firstname;
    this.surname = dto.model.surname;
    this.telephoneNumber = dto.model.telephoneNumber;
    this.email = dto.model.email;
    this.street = dto.model.street;
    this.streetNumber = dto.model.streetNumber;
    this.zipCode = dto.model.zipCode;
    this.city = dto.model.city;
    this.title = dto.model.title;
    this.text = dto.model.text;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      firstname: Yup.string().required(),
      surname: Yup.string().required(),
      telephoneNumber: Yup.string().optional().nullable(),
      email: Yup.string().email().required(),
      street: Yup.string().required(),
      streetNumber: Yup.string().optional().nullable(),
      zipCode: Yup.number().required(),
      city: Yup.string().required(),
      title: Yup.string().required(),
      text: Yup.string().required(),
    });
  }
}
