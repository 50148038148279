import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { ProductModel } from "@/models/ProductModel";
import { ProductDto } from "../ProductDtos";
import { IProductModel } from "@/models/interfaces/IProductModel";
import { toRaw } from "vue";
import { ProductImageModel } from "@/models/ProductImageModel";
import { ProductCategoryModel } from "@/models/ProductCategoryModel";

export class ProductFormData extends BaseFormData<IProductModel> implements IProductModel {
  public articleNumber: string;
  public nameDe: string;
  public nameFr: string;
  public subnameDe?: string | null;
  public subnameFr?: string | null;
  public descriptionDe: string;
  public descriptionFr: string;
  public price: number;
  public stock: number;
  public isActive: boolean;
  public colorTheme: string;
  public isHighlight: boolean;
  public productImages: ProductImageModel[];
  public productCategories: ProductCategoryModel[];
  public similarProducts: ProductModel[];

  public originalData: ProductModel;

  constructor(dto: ProductDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.isActive = dto.model.isActive;
    this.articleNumber = dto.model.articleNumber;
    this.nameDe = dto.model.nameDe;
    this.nameFr = dto.model.nameFr;
    this.subnameDe = dto.model.subnameDe;
    this.subnameFr = dto.model.subnameFr;
    this.descriptionDe = dto.model.descriptionDe;
    this.descriptionFr = dto.model.descriptionFr;
    this.price = dto.model.price;
    this.stock = dto.model.stock;
    this.colorTheme = dto.model.colorTheme;
    this.isHighlight = dto.model.isHighlight;
    this.productImages = dto.model.productImages;
    this.productCategories = dto.model.productCategories;
    this.similarProducts = dto.model.similarProducts;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      isActive: Yup.boolean().default(true),
      articleNumber: Yup.string().required(),
      nameDe: Yup.string().required(),
      nameFr: Yup.string().required(),
      subnameDe: Yup.string().optional().nullable(),
      subnameFr: Yup.string().optional().nullable(),
      descriptionDe: Yup.string().required(),
      descriptionFr: Yup.string().required(),
      price: Yup.number().required(),
      stock: Yup.number().required(),
      colorTheme: Yup.string().required(),
      isHighlight: Yup.boolean().required(),
      productImages: Yup.array().required().min(1),
      productCategories: Yup.array().defined(),
      similarProducts: Yup.array().defined(),
    });
  }
}
