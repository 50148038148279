import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { UserDto } from "../UserDtos";
import { toRaw } from "vue";
import { UserModel } from "@/models/UserModel";
import { IControlPanelUserModel } from "@/models/interfaces/IControlPanelUserModel";

export class UserControlPanelFormData extends BaseFormData<IControlPanelUserModel> implements IControlPanelUserModel {
  public isActive: boolean;
  public username: string;
  public displayName: string;
  public password?: string | null;
  public hasNewsletter: boolean;
  public notes: string | undefined;

  public originalData: UserModel;

  constructor(dto: UserDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.isActive = dto.model.isActive;
    this.username = dto.model.username;
    this.displayName = dto.model.displayName;
    this.password = null;
    this.hasNewsletter = dto.model.hasNewsletter;
    this.notes = dto.model.notes ?? "";

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      isActive: Yup.boolean().default(true),
      displayName: Yup.string().required(),
      username: Yup.string().email().required(),
      password: Yup.string().optional().nullable(),
      hasNewsletter: Yup.boolean().required(),
      notes: Yup.string().optional(),
    });
  }
}
