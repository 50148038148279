import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { IContactFormEntryModel } from "./interfaces/IContactFormModel";

export class ContactFormEntryModel extends BaseModel implements IContactFormEntryModel {
  public firstname: string;
  public surname: string;
  public telephoneNumber?: string | null;
  public email: string;
  public street: string;
  public streetNumber?: string | null;
  public zipCode: number | null;
  public city: string;
  public title: string;
  public text: string;

  constructor() {
    super();

    this.firstname = "";
    this.surname = "";
    this.telephoneNumber = null;
    this.email = "";
    this.street = "";
    this.streetNumber = null;
    this.zipCode = null;
    this.city = "";
    this.title = "";
    this.text = "";
  }

  public static readonly mappings: TypeMapping[] = [];
}
