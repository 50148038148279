<template>
  <fieldset :disabled="isArchived(dto.model)" v-if="form.isReady()">
    <Message v-if="isArchived(dto.model)" severity="warn" class="archived" :closable="false">Diese Bestellung ist archiviert.</Message>

    <form @submit.prevent="">
      <Panel header="Bestellungsübersicht">
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-book"></i>
            <InputText type="text" id="orderNumber" :disabled="true" v-model="dto.model.orderNumber" />
            <label for="orderNumber">Bestell-Nummer</label>
          </span>

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar id="orderDate" :disabled="true" date-format="dd.mm.yy," showTime hourFormat="24" v-model="dto.model.orderDate" />
            <label for="orderDate">Bestellt am</label>
          </span>
        </div>

        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="displayName" readonly v-model="dto.model.user.displayName" class="clickable" @click="goToUser" />
            <label for="displayName">Bestellt durch</label>
          </span>

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-gift"></i>
            <InputText v-if="dto.model.coupon" type="text" id="coupon" readonly v-model="dto.model.coupon.code" :class="{ clickable: hasOrderCoupon(dto.model) }" @click="goToCoupon" />
            <label for="coupon">Verwendeter Coupon</label>
          </span>
        </div>

        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-hourglass"></i>
            <InputText type="text" id="status" :disabled="true" />
            <label for="status" class="p-float float">Status</label>
            <label>
              <span>
                <Tag v-if="dto.model.deliveryStatus === DeliveryStatus.CANCELED" :severity="'error'">Storniert</Tag>
                <Tag v-if="dto.model.deliveryStatus === DeliveryStatus.DELIVERED" :severity="'success'">Versendet</Tag>
                <Tag v-else-if="dto.model.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED" :severity="'info'">Teilweise versendet</Tag>
                <Tag v-else-if="dto.model.deliveryStatus === DeliveryStatus.UNPROCESSED" :severity="'warning'">In Bearbeitung</Tag>
                <label v-if="dto.model.deliveryStatus === DeliveryStatus.DELIVERED" class="date-info"></label>
              </span>
            </label>
          </span>
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar v-if="dto.model.shippingDate" id="shippingDate" :disabled="true" date-format="dd.mm.yy," showTime hourFormat="24" v-model="dto.model.shippingDate" />
            <InputText v-else type="text" id="shippingDate" :disabled="true" value="-" />
            <label for="shippingDate" class="p-float">{{ dto.model.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED ? "Letzes Produkt versendet am" : "Bestellung versendet am" }}</label>
          </span>
        </div>

        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar v-if="dto.model.cancelationDate" id="cancelationDate" :disabled="true" date-format="dd.mm.yy," showTime hourFormat="24" v-model="dto.model.cancelationDate" />
            <InputText v-else type="text" id="cancelationDate" :disabled="true" value="-" />
            <label for="cancelationDate" class="p-float">Storniert am</label>
          </span>
          <span>&nbsp;</span>
        </div>
      </Panel>

      <Panel header="Bestellung bearbeiten">
        <div class="form-row split split-special">
          <div class="form-row split m-0">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-ticket"></i>
              <InputNumber type="text" id="id" :disabled="dto.isLoading" :use-grouping="false" :min="0" v-model="form.receivedSachets" />
              <label for="id">Erhaltene Sachets</label>
            </span>
            <div style="width: 10%">
              <p>von {{ computeTotalCost(dto.model) }}</p>
            </div>
          </div>
          <div>
            <Button
              type="submit"
              label='"Sachets Erhalten" verschicken'
              icon="pi pi-envelope"
              severity="info"
              @click="sendSachetsReceivedMail"
              :disabled="!form.isValidForm() || dto.model.receivedSachets === 0"
              :loading="dto.isLoading"
            >
            </Button>
          </div>
        </div>
        <Message severity="info" class="info small" :closable="false"
          ><strong>Hinweis: </strong>Um eine E-Mail-Benachrichtigung and die Kund:in zu schicken, verändere die Zahl in "Erhaltene Sachets" und speichere zuerst. Klicke danach auf den Button
          "'Erhaltene Sachets' verschicken".</Message
        >

        <div class="form-row">
          <div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-truck"></i>
              <InputText type="text" id="trackingNumber" :disabled="dto.isLoading" v-model="form.trackingNumber" />
              <label for="trackingNumber">SwissPost Tracking-Nummer</label>
            </span>
          </div>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-list"></i>
            <Textarea id="notes" :disabled="dto.isLoading" rows="6" v-model="form.notes"></Textarea>
            <label for="notes">Notizen<sup>1</sup></label>
          </span>
          <small class="p-info"><sup>1</sup>Notizen sind nur für Administratoren sichtbar, nicht für Benutzer.</small>
        </div>
        <Divider />

        <!-- Button Panel -->
        <div class="button-panel">
          <div></div>
          <div>
            <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
            <Button type="submit" label="Speichern" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm() || isArchived(dto.model)" :loading="dto.isLoading"></Button>
          </div>
        </div>
      </Panel>

      <Message v-if="dto.hasError" severity="error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</Message>

      <div v-if="dto.model.orderProducts">
        <DataTable :value="dto.model.orderProducts" :loading="dto.isLoading" dataKey="id" filterDisplay="menu" size="small" stripedRows>
          <template #loading> <ProgressSpinner strokeWidth="2" /> </template>
          <Column sortable filter field="amountDelivered" header="Status" header-style="width: 200px">
            <template #body="slotProps">
              <Tag v-if="dto.model.deliveryStatus === DeliveryStatus.CANCELED" :severity="'error'">Storniert</Tag>
              <Tag v-else-if="slotProps.data.deliveryStatus === DeliveryStatus.DELIVERED" :severity="'success'">Versendet</Tag>
              <Tag v-else-if="slotProps.data.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED" :severity="'info'">Teilweise versendet</Tag>
              <Tag v-else-if="slotProps.data.deliveryStatus === DeliveryStatus.UNPROCESSED" :severity="'warning'">Versand wird vorbereitet</Tag>
            </template>
          </Column>
          <Column header="Bereits versendet">
            <template #body="slotProps">{{ slotProps.data.amountDelivered }} von {{ slotProps.data.amountOrdered }}</template>
          </Column>
          <Column header="Versendet am">
            <template #body="slotProps">
              <DateDisplay v-if="slotProps.data.shippingDate" :date="slotProps.data.shippingDate" :withTime="true"></DateDisplay>
              <span v-else>-</span>
            </template>
          </Column>
          <Column sortable filter field="product.articleNumber" header="Produkt">
            <template #body="slotProps">
              <span class="clickable" @click="gotToProductDetail(slotProps.data.product.id)">{{ slotProps.data.product.articleNumber }} {{ slotProps.data.product.nameDe }} {{ slotProps.data.product.subnameDe }}</span>
            </template>
          </Column>
          <Column sortable filter field="product.price" header="Preis" header-class="header-align-right">
            <template #body="slotProps">
              <span class="align-right">{{ slotProps.data.product.price }}<span v-if="!isMobile">&nbsp;Sachets</span></span>
            </template>
          </Column>
          <Column sortable filter field="sellingPrice" header="Preis Total" header-class="header-align-right">
            <template #body="slotProps">
              <span class="align-right">{{ slotProps.data.sellingPrice * slotProps.data.amountOrdered }}<span v-if="!isMobile">&nbsp;Sachets</span></span>
            </template>
          </Column>
          <Column header="Aktionen" header-style="direction:rtl;" body-style="text-align:right">
            <template #body="slotProps">
              <div v-if="dto.model.deliveryStatus !== DeliveryStatus.CANCELED">
                <InputNumber v-model="slotProps.data.amountOrderedForm" showButtons :useGrouping="false" :min="0" :max="slotProps.data.amountOrdered"> </InputNumber>
                <span> / {{ slotProps.data.amountOrdered }}</span>
                <Button icon="pi pi-check" label="als versendet markieren" severity="info" @click="(e) => markAsDelivered(e, slotProps.data.id)"></Button>
              </div>
              <span v-else>-</span>
            </template>
          </Column>
          <template #footer>
            <span v-if="hasOrderCoupon(dto.model)" class="flex justify-content-between sum">
              <span>Zwischentotal</span>
              <span>{{ computeTotalCostWithoutReduction(dto.model) }}<span v-if="!isMobile">&nbsp;Sachets</span> </span>
            </span>
            <span v-if="hasOrderCoupon(dto.model)" class="flex justify-content-between sum midsum">
              <span>
                Gutscheincode '<router-link class="clickable" :to="`/controlpanel/coupons/${dto.model.coupon?.id}`">{{ dto.model.coupon?.code }} </router-link>'
              </span>
              <span>-{{ dto.model.coupon?.reductionInPacks }}<span v-if="!isMobile">&nbsp;Sachets</span></span>
            </span>
            <span class="flex justify-content-between sum">
              <span>Total</span>
              <span>{{ computeTotalCost(dto.model) }}<span v-if="!isMobile">&nbsp;Sachets</span> </span>
            </span>
          </template>
        </DataTable>

        <Message severity="info" class="info small" :closable="false"><strong>Wichtig: </strong>Produkte als versendet zu markieren löst keine E-Mails aus.</Message>
      </div>

      <ManagementButtonPanel header="Weitere Aktionen">
        <div class="form-row split">
          <label>Bestellung verwalten</label>
          <Button v-if="dto.model.deliveryStatus !== DeliveryStatus.CANCELED" label="Bestellung stornieren" :severity="'error'" @click="cancelOrder"></Button>
          <span v-else>Diese Bestellung wurde storniert.</span>
        </div>
      </ManagementButtonPanel>

      <MetadataPanel :dto="dto"></MetadataPanel>
    </form>
  </fieldset>
  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>
  <Toast />
  <ConfirmDialog />
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs, watch } from "vue";
import { OrderDto, OrderProductDto } from "@/dtos/OrderDtos";
import { FormHelper } from "@/helpers/FormHelper";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import ManagementButtonPanel from "@/components/shared/ManagementButtonPanel.vue";
import OrdersDataTable from "@/components/shared/OrdersDataTable.vue";
import orderMixins from "@/mixins/orderMixins";
import { Constants } from "@/enums/Constants";
import { useBreakpoints } from "@vueuse/core";
import { useOrderStore } from "@/stores/orderStore";
import { useToast } from "primevue/usetoast";
import { ToastMessageOptions } from "primevue/toast";
import { DeliveryStatus } from "@/enums/DeliveryStatus";
import { OrderFormData } from "@/dtos/data/OrderFormData";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Validator } from "@/helpers/Validator";
import { IOrderEditModel } from "@/models/interfaces/IOrderEditModel";
import { OrderProductModel } from "@/models/OrderProductModel";
import MetadataPanel from "../MetadataPanel.vue";
import modelMixins from "@/mixins/modelMixins";
/**
 * A shared component used for editing (and creating) orders.
 */
export default defineComponent({
  name: "OrdersCreateEdit",
  components: { LoadingSkeleton, DateDisplay, ManagementButtonPanel, OrdersDataTable, MetadataPanel },
  props: {
    id: Number,
    dto: {
      type: OrderDto,
      required: true,
    },
  },
  methods: {
    gotToProductDetail(id: number): void {
      this.$router.push(`/controlpanel/products/${id}`);
    },

    goToCoupon(): void {
      if (this.hasOrderCoupon(this.dto.model)) {
        this.$router.push(`/controlpanel/coupons/${this.dto.model.couponId}`);
      }
    },

    goToUser(): void {
      this.$router.push(`/controlpanel/users/${this.dto.model.userId}`);
    },

    sendSachetsReceivedMail(event: Event) {
      const orderStore = useOrderStore();

      this.formHelper.prompt(
        event,
        `Möchtest du den Kunden/die Kundin informieren, dass ${this.dto.model.receivedSachets} von ${this.computeTotalCost(this.dto.model)} Sachets erhalten wurden?`,
        "Benachrichtigung verschicken?",
        "E-Mail verschicken",
        "Abbrechen",
        "pi pi-envelope",
        () => {
          // send update request.
          orderStore
            .sendSachetsReceivedMail(this.dto)
            .then(async () => {
              // show toast if successful.
              this.showToast({
                life: 5000,
                severity: "success",
                summary: "Erfolgreich verschickt",
                detail: "Das E-Mail wurde erfolgreich an den Benutzer verschickt.",
              });
            })
            .catch((error) => {
              // show toast if failed.
              this.showToast({
                life: 6000,
                severity: "error",
                summary: "Fehlgeschlagen",
                detail: "Das E-Mail konnte nicht verschickt werden. Versuche es später erneut.",
              });
            });
        },
        () => {}
      );
    },

    markAsDelivered(event: Event, id: number): void {
      const orderStore = useOrderStore();
      const orderProductModel = this.dto.model.orderProducts.find((o) => o.id === id);

      this.formHelper.prompt(
        event,
        `Möchtest du den Versand für dieses Produkt von ${orderProductModel?.amountDelivered} auf ${orderProductModel?.amountOrderedForm} (bestellt: ${orderProductModel?.amountOrdered}) setzen?`,
        "Versandstatus aktualisieren?",
        "Aktualisieren",
        "Abbrechen",
        "",
        () => {
          if (orderProductModel) {
            // update amount delivered in model.
            orderProductModel.amountDelivered = orderProductModel.amountOrderedForm;

            // send update request.
            const orderProductDto = new OrderProductDto(orderProductModel);
            orderStore
              .updateOrderProduct(this.dto.model.id, orderProductDto)
              .then(async () => {
                // copy updated values.
                ObjectHelper.copyExistingPropsFromTo(orderProductDto.model, orderProductModel, OrderProductModel.mappings);
                this.dto.model.shippingDate = orderProductModel.shippingDate;
                this.dto.model.deliveryStatus = orderProductDto.model.order.deliveryStatus;

                // show toast if successful.
                this.showToast({
                  life: 5000,
                  severity: "success",
                  summary: "Erfolgreich aktualisiert",
                  detail: "Die gelieferte Menge wurde erfolgreich aktualisiert.",
                });
              })
              .catch((error) => {
                // show toast if failed.
                this.showToast({
                  life: 6000,
                  severity: "error",
                  summary: "Fehlgeschlagen",
                  detail: "Die Bestellung konnte nicht storniert werden. Versuche es später erneut.",
                });
              });
          }
        },
        () => {}
      );
    },

    cancelOrder(event: Event): void {
      const orderStore = useOrderStore();

      this.formHelper.prompt(
        event,
        "Möchtest du wirklich diese Bestellung stornieren?",
        "Bestellung stornieren?",
        "Stornieren",
        "Abbrechen",
        "pi pi-stop-circle",
        () => {
          // send cancellation request.
          orderStore
            .cancelOrder(this.dto)
            .then(async () => {
              // show toast if successful.
              this.showToast({
                life: 5000,
                severity: "success",
                summary: "Erfolgreich aktualisiert",
                detail: "Alle Produkte wurden erfolgreich als geliefert markiert.",
              });
            })
            .catch((error) => {
              // show toast if failed.
              this.showToast({
                life: 6000,
                severity: "error",
                summary: "Fehlgeschlagen",
                detail: "Die Bestellung konnte nicht storniert werden. Versuche es später erneut.",
              });
            });
        },
        () => {}
      );
    },

    async onSave() {
      const store = useOrderStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // validate before save.
      this.validator.validateForm(this.form as OrderFormData, this.dto).then(() => {
        // update form values.
        this.dto.model.orderProducts.forEach((orderProduct) => {
          orderProduct.amountOrderedForm = orderProduct.amountDelivered;
        });

        // send PUT request and show toast if successful.
        store.updateOrder(this.dto as OrderDto).then(async () => {
          this.showToast({
            life: 5000,
            severity: "success",
            summary: "Erfolgreich gespeichert",
            detail: "Die Bestellung wurde erfolgreich aktualisiert.",
          });
        });
      });
    },
  },
  setup(props) {
    const toast = useToast();
    const validator = new Validator<IOrderEditModel>();
    const formHelper = new FormHelper("/controlpanel/orders");

    // get entity from props.
    const { id, dto } = toRefs(props);

    const form = ref(new OrderFormData(dto.value));

    // setup form watchers.
    const updateFormData = (newValue: OrderDto) => {
      if (newValue.model.id !== undefined && !form.value.isExistingOrNew()) {
        form.value = new OrderFormData(newValue);

        // update form values.
        dto.value.model.orderProducts.forEach((orderProduct) => {
          orderProduct.amountOrderedForm = orderProduct.amountDelivered;
        });
      }
    };
    watch(dto.value, updateFormData);

    const hasOrderCoupon = orderMixins.hasOrderCoupon;
    const computeTotalCost = orderMixins.computeTotalCost;
    const computeTotalCostWithoutReduction = orderMixins.computeTotalCostWithoutReduction;

    const isArchived = modelMixins.isOrderArchived;

    // import breakpoints for responsiveness.
    const breakpoints = useBreakpoints(Constants.Breakpoints);
    const isMobile = breakpoints.isSmallerOrEqual("mobile");

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      id,
      dto,
      validator,
      form,
      formHelper,
      showToast,
      isMobile,
      isArchived,
      hasOrderCoupon,
      computeTotalCost,
      computeTotalCostWithoutReduction,
      DeliveryStatus,
    };
  },
});
</script>

<style scoped lang="scss">
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.date-info {
  white-space: nowrap;
  font-size: 0.8rem;
  margin-top: -5px;
  margin-left: 5px;
}

.p-panel {
  margin-bottom: 40px;
}
.p-datatable {
  button {
    margin-left: 10px;
  }

  :deep(.p-inputnumber-input) {
    width: 50px;
    padding: 0.75rem 0.75rem;
  }
}

.actions {
  margin-top: 40px;
}

.midsum {
  font-weight: normal;
}

.sum {
  &:first-child {
    border: none;
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  border-top: 1px solid $ff-controlpanel-lighter;
  padding: 5px 0;
}

.split-special {
  > div:first-child {
    width: 70%;
  }
  > div:last-child {
    width: 30%;
    white-space: pre;
    display: flex;
    justify-content: flex-end;
  }
}

.p-message {
  &.info {
    border: solid #0ea5e9;
    border-width: 0 0 0 6px;
    color: #0ea5e9;

    :deep(svg) {
      color: #0ea5e9;
    }
  }

  &.small {
    :deep(.p-message-wrapper) {
      padding: 10px 15px;
    }
  }
}
</style>
