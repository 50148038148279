<template>
  <div class="login-panel">
    <router-link to="/login" class="breadcrumb">
      <i class="pi pi-angle-left"></i> <span><Translate text="STOREFRONT_NAVIGATION_TO_LOGIN" /></span>
    </router-link>

    <h2><Translate text="STOREFRONT_FORMS_FORGOTPASSWORD" /></h2>

    <form v-if="isProcessStartedSuccessfully" @submit.prevent="" @change="validator.validateForm(form as PasswordResetFormData, dto)">
      <Message severity="success" :closable="false"><Translate text="STOREFRONT_MESSAGES_FORGOTPASSWORD_SUCCESS" /></Message>

      <FFButton :label="Translator.translate('STOREFRONT_FORMS_FORGOTPASSWORD_TO_LOGIN')" @click="goToLogin()" :loading="dto.isLoading" color="var(--ff-random-label-color)"></FFButton>
    </form>

    <form v-else @submit.prevent="" @change="validator.validateForm(form as PasswordResetFormData, dto)">
      <span class="p-float-label p-input-icon-left">
        <i class="pi pi-user"></i>
        <InputText
          type="text"
          id="username"
          name="username"
          autocomplete="username"
          v-on:keyup.enter="forgotPassword()"
          :disabled="dto.isLoading"
          v-model="form.username"
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'username') }"
        />
        <label for="username"><Translate text="STOREFRONT_FORMS_FORGOTPASSWORD_USERNAME" /></label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'username')"><Translate text="STOREFRONT_FORMS_FORGOTPASSWORD_USERNAME_VALIDATION" /></small>

      <FFButton :label="Translator.translate('STOREFRONT_FORMS_FORGOTPASSWORD_SUBMIT')" @click="forgotPassword()" :loading="dto.isLoading" color="var(--ff-random-label-color)"></FFButton>

      <Message v-if="dto.hasError" severity="error">{{ customError || Translator.translate("STOREFRONT_MESSAGES_GENERIC_ERROR") }}</Message>
    </form>
  </div>
</template>

<script lang="ts">
import { UserDto } from "@/dtos/UserDtos";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Validator } from "@/helpers/Validator";
import { UserModel } from "@/models/UserModel";
import { defineComponent, ref } from "vue";
import FFButton from "@/components/storefront/FFButton.vue";
import { useUserProfileStore } from "@/stores/userProfileStore";
import { PasswordResetData } from "@/dtos/data/PasswordResetData";
import { PasswordResetFormData } from "@/dtos/data/PasswordResetFormData";
import { StatusCode } from "@/enums/StatusCode";
import { UserViewModel } from "@/viewModels/ViewModels";
import { AxiosResponse } from "axios";
import { Translator } from "@/helpers/Translator";
import Translate from "@/components/shared/Translate.vue";
import { useHead } from "@unhead/vue";
import stringMixins from "@/mixins/stringMixins";

/**
 * The shared login forgot-password component.
 */
export default defineComponent({
  name: "ForgotPassword",
  components: { FFButton, Translate },
  methods: {
    async forgotPassword(): Promise<void> {
      const store = useUserProfileStore();

      this.form.shouldValidate = true;

      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      this.validator.validateForm(this.form as PasswordResetFormData, this.dto).then((success) => {
        store
          .startPasswordResetProcess(this.dto, this.form)
          .then(() => {
            this.isProcessStartedSuccessfully = true;
          })
          .catch((error: AxiosResponse<UserViewModel>) => {
            switch (error.data.statusCode) {
              case StatusCode.USER_NOT_FOUND:
                this.customError = Translator.translate("STOREFRONT_MESSAGES_FORGOTPASSWORD_USER_NOT_FOUND");
                break;
            }
          });
      });
    },

    goToLogin(): void {
      this.$router.push("/login");
    },
  },
  setup() {
    const validator = new Validator<PasswordResetData>();
    const formHelper = new FormHelper("/login");

    const dto = ref<InstanceType<typeof UserDto>>(new UserDto(new UserModel()));
    const form = ref<InstanceType<typeof PasswordResetFormData>>(new PasswordResetFormData(dto.value));
    const isProcessStartedSuccessfully = ref(false);
    const customError = ref("");

    // add SEO.
    useHead({
      title: () => stringMixins.formatSeoTitle(Translator.translate("STOREFRONT_FORMS_FORGOTPASSWORD")),
    });

    return {
      validator,
      formHelper,
      form,
      dto,
      isProcessStartedSuccessfully,
      customError,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss"></style>
